import React, {ReactElement} from "react";
import {Props as ViewProps, View} from "../../../Components/SwiftUI/View";
import {Text} from "../../../Components/SwiftUI/Text";
import {Color} from "../../../Components/SwiftUI/Enums/Color";
import {FontWeight} from "../../../Components/SwiftUI/Enums/FontWeight";
import {MediaType, Toolbox} from "../../../Utilities/Toolbox";
import {VStack} from "../../../Components/SwiftUI/VStack";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {Spacer} from "../../../Components/SwiftUI/Spacer";
import {Group} from "../../../Components/SwiftUI/Group";
import {Image} from "../../../Components/SwiftUI/Image";
import {IconType, IconV} from "../../../Components/IconV";
import {AppThumbnailV} from "../../../Components/AppThumbnailV";
import {Fonts} from "../../../Constants/Fonts";
import {NavigationLink} from "../../../Components/SwiftUI/NavigationLink";
import {HorizontalAlignment} from "../../../Components/SwiftUI/Enums/HorizontalAlignment";

import crownSymbol from "../../../Assets/crown.fill.svg"
import arrowUpSymbol from "../../../Assets/arrow.up.svg"
import arrowDownSymbol from "../../../Assets/arrow.down.svg"
import ellipsisSymbol from "../../../Assets/ellipsis.svg"
import {ExpandableText} from "../../../Components/ExpandableText";

interface Props extends ViewProps {
    comment: any
    showEntity: boolean
    showReplyCount?: boolean
}

export const CommentThumbnailV: React.FC<Props> = (props) => {

    const {comment} = props

    function votesLabel(): ReactElement | null {
        const score = comment.upvoteCount - comment.downvoteCount
        let showsScore = comment.upvoteCount !== 0 || comment.downvoteCount !== 0
        if (showsScore) {
            return <Text t={Toolbox.formatNumber(score, true)}
                         foregroundStyle={score > 0 ? Color.upvote : score < 0 ? Color.downvote : Color.lightGray} />
        } else {
            return null
        }
    }

    function text(): string {
        if (comment.deleted) {
            return "*Comment deleted.*";
        } else if (comment.parent) {
            const parent = comment.parent;
            return `[@${parent.user.displayName}](${parent.id}) ${comment.text}`;
        } else {
            return comment.text;
        }
    }

    function userV() {
        return (
            <>
                <IconV src={Toolbox.mediaById(comment.user.iconSmall, MediaType.userIcon)} iconType={IconType.user} />
                <Text t={comment.user.displayName}
                      foregroundStyle={Toolbox.usernameColor(comment.user.id, comment.user.role)}
                      font={{size: 16, weight: FontWeight.semibold}} />
            </>
        )
    }

    return (
        <VStack stretch alignment={HorizontalAlignment.leading} spacing={0}
                padding={{horizontal: 16, top: 8}}
                cornerRadius={15}
                shadow={{color: Color.lightBorder, radius: 4}}
                {...props}
        >

            <HStack frame={{height: 30}}>
                {userV()}
                <Spacer />
                <Group font={{size: 14}} >
                    {comment.premiumOnly ?
                        <Image systemName={crownSymbol}
                               foregroundStyle={Color.premium} />
                    : null}
                    <Text t={Toolbox.formatDate(new Date(comment.timestamp), true)}
                          foregroundStyle={Color.lightGray} />
                </Group>
            </HStack>

            <Spacer frame={{height: 4}} />

            {props.showEntity && comment.entity.title &&
                <NavigationLink stretch destination={`/app/${comment.entity.id}`} foregroundStyle={Color.white}>
                    <AppThumbnailV app={comment.entity}
                                   frame={{height: 62+4+8}}
                                   padding={{top: 4, leading: -4, bottom: 8, trailing: -4}} />
                </NavigationLink>
            }

            <Spacer frame={{height: 4}} />

            <ExpandableText lk={text()}
                  lineLimit={7}
                  foregroundStyle={comment.deleted ? Color.gray : undefined}
                  font={Fonts.body} />

            <Spacer minLength={0} />

            <HStack spacing={0}
                    foregroundStyle={Color.lightGray}
                    font={{size: 15, weight: FontWeight.semibold}}
                    lineLimit={1}
                    frame={{height: 34}}
                    opacity={comment.deleted ? 0 : 1}
            >

                {comment.hasReplies && (props.showReplyCount ?? true) &&
                    <NavigationLink fit destination={`/comment/${comment.id}/replies`}>
                        <Text t={`${comment.parent == null ? comment.commentCount : comment.replyCount} replies`} />
                    </NavigationLink>
                }

                <Spacer minLength={0} />

                <NavigationLink fit destination={"/getAppRaven"}>
                    <View frame={{height: 34}}>
                        <Image systemName={ellipsisSymbol} frame={{width: 34, height: 3}} />
                    </View>
                </NavigationLink>

                <Spacer frame={{width: 8}} />

                <HStack fit>
                    <NavigationLink fit destination={"/getAppRaven"}>
                        <HStack frame={{height: 34}}>
                            <Image systemName={arrowUpSymbol} frame={{height: 15}} />
                            {votesLabel()}
                        </HStack>
                    </NavigationLink>
                    <NavigationLink fit destination={"/getAppRaven"}>
                        <View frame={{height: 34}}>
                            <Image systemName={arrowDownSymbol} frame={{height: 15}} />
                        </View>
                    </NavigationLink>
                </HStack>

                <Spacer frame={{width: 16}} />

                <NavigationLink fit t={"Reply"} destination={"/getAppRaven"}
                        frame={{height: 34}} />

            </HStack>
        </VStack>
    )
}
