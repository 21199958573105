import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {HStack} from "./SwiftUI/HStack";
import {Image} from "./SwiftUI/Image";
import {ForEach} from "./SwiftUI/ForEach";

const fullStar = require("../Assets/star.fill@3x.png");
const halfStar = require("../Assets/star.leadinghalf.filled@3x.png");
const emptyStar = require("../Assets/star@3x.png");

interface Props extends ViewProps {
    rating: number
}

export const RatingStarsV: React.FC<Props> = (props) => {
    return (
        <HStack fit spacing={2} {...props}>
            <ForEach data={[1, 2, 3, 4, 5]} id={index => index} content={index => {
                if (props.rating > index - 0.25) {
                    return <Image src={fullStar} />
                } else if (props.rating > index - 0.75) {
                    return <Image src={halfStar} />
                } else {
                    return <Image src={emptyStar} />
                }
            }} />
        </HStack>
    );
};
