import React, {ReactElement} from "react";
import {Props as ViewProps, View} from "./View";
import {Axis} from "./Enums/Axis";

interface Props extends ViewProps {
    axes?: Axis
    showsIndicators?: boolean
}

export const ScrollView: React.FC<Props> = (props) => {

    let horizontal = props.axes === Axis.horizontal

    const style: React.CSSProperties = {
        width: "100%",
        height: "100%",
        maxWidth: "100vw",
        overflowX: horizontal ? "scroll" : "hidden",
        overflowY: horizontal ? "hidden" : "scroll",
        scrollSnapType: horizontal ? "x mandatory" : "y mandatory",
        ...props.style
    }

    const className = props.showsIndicators === false ? "scrollIndicatorsHidden" : undefined

    return (
        <View {...props} style={style} className={className}>
            {React.Children.map(props.children, child => {
                if (!React.isValidElement(child)) { return child }
                return React.cloneElement(child as ReactElement, {
                    style: {
                        width: horizontal ? "fit-content" : undefined,
                        height: horizontal ? undefined : "fit-content",
                        ...child.props.style
                    }
                })
            })}
        </View>
    )
}