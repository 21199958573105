import React from 'react';
import './ProgressView.css';
import {Props as ViewProps} from "./View";
import {ZStack} from "./ZStack";

interface Props extends ViewProps {}

export const ProgressView: React.FC<Props> = (props) => {
    return (
        <ZStack {...props}>
            <div className="ios-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </ZStack>
    );
};