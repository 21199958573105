import {gql} from "@apollo/client";

export const UserThumbnail = gql`
fragment UserThumbnail on User {
    id
    displayName
    iconMedium
    premium
    score
    role
    youFollowHim
    heFollowsYou
    appCount
    commentCount
}
`