import React, {SetStateAction, useEffect, useState} from "react";
import {useLocation, useNavigationType} from "react-router-dom";

export const useStoredState = <T,>(name: string, initialState: T, storage: boolean = false, persistent: boolean = true): [T, React.Dispatch<SetStateAction<T>>] => {

    const action = useNavigationType()
    const location = useLocation()

    function getStateFromUrl() {
        const params = new URLSearchParams(location.search);
        const item = params.get(name)
        return item ? (item as T ?? JSON.parse(item)) : null
    }

    function getSavedState(): T {
        if (storage && (action === "POP" || persistent)) {
            const item = localStorage.getItem(name)
            return item ? JSON.parse(item) : initialState
        } else if (!storage && action === "POP") {
            return getStateFromUrl() ?? initialState
        } else {
            return initialState
        }
    }

    const [state, setState] = useState(getSavedState());

    useEffect(() => {
        const urlState = getStateFromUrl() ?? initialState
        if (urlState != state) {
            setState(urlState)
        }
    }, [location.search]);

    useEffect(() => {
        if (storage && !persistent && action !== "POP") {
            localStorage.removeItem(name);
        }
    }, [action]);

    useEffect(() => {
        if (storage) {
            localStorage.setItem(name, JSON.stringify(state))
        } else {
            const params = new URLSearchParams(location.search);
            if (state != initialState) {
                params.set(name, (state as string) ?? JSON.stringify(state));
            } else {
                params.delete(name)
            }
            const query = params.toString()
            const newUrl = query.length > 0 ? `${location.pathname}?${params.toString()}` : location.pathname;
            window.history.replaceState(null, '', newUrl);
        }
    }, [state]);

    return [state, setState];
}