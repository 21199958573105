export enum AppSortBy {
    addedDate = "ADDED_DATE",
    ageRating = "AGE_RATING",
    commentCount = "COMMENT_COUNT",
    downvoteCount = "DOWNVOTE_COUNT",
    lastPriceDropDate = "LAST_PRICE_DROP_DATE",
    lastUpdateDate = "LAST_UPDATE_DATE",
    ownCount = "OWN_COUNT",
    popularityDay = "POPULARITY_DAY",
    popularityMonth = "POPULARITY_MONTH",
    popularityWeek = "POPULARITY_WEEK",
    price = "PRICE",
    rating = "RATING",
    ratingCount = "RATING_COUNT",
    releaseDate = "RELEASE_DATE",
    score = "SCORE",
    size = "SIZE",
    title = "TITLE",
    upvoteCount = "UPVOTE_COUNT",
    watchCount = "WATCH_COUNT"
}