export enum Color {
    red    = "rgb(255, 59, 48)",
    orange = "rgb(255, 149, 0)",
    yellow = "rgb(255, 204, 0)",
    green  = "rgb(52, 199, 89)",
    mint   = "rgb(0, 199, 190)",
    teal   = "rgb(48, 176, 199)",
    cyan   = "rgb(50, 173, 230)",
    blue   = "rgb(0, 122, 255)",
    indigo = "rgb(88, 86, 214)",
    purple = "rgb(175, 82, 222)",
    pink   = "rgb(255, 45, 85)",
    brown  = "rgb(162, 132, 94)",
    clear  = "rgb(0, 0, 0, 0)",

    gray = "rgb(142, 142, 147)",
    gray2 = "rgb(174, 174, 178)",
    gray3 = "rgb(199, 199, 204)",
    gray4 = "rgb(209, 209, 214)",
    gray5 = "rgb(229, 229, 234)",
    gray6 = "rgb(242, 242, 247)",

    black     = "rgb(0, 0, 0)",
    white     = "rgb(255, 255, 255)",
    lightGray = "rgba(170, 170, 170)",
    darkGray  = "rgb(85, 85, 85)",

    groupedBackground = gray6,

    // Extra Colors

    navy    = "rgb(0, 0, 128)",
    olive   = "rgb(128, 128, 0)",
    maroon  = "rgb(128, 0, 0)",
    lime    = "rgb(0, 255, 0)",
    aqua    = "rgb(0, 255, 255)",
    fuchsia = "rgb(255, 0, 255)",
    silver  = "rgb(192, 192, 192)",
    gold    = "rgb(255, 215, 0)",

    // My Colors

    tint    = "rgb(0, 150, 255)",

    upvote      = "rgb(38, 188, 6)",
    downvote    = "rgb(240, 76, 70)",
    comment     = gray,
    watch       = "rgb(255, 170, 0)",
    own         = "rgb(255, 100, 255)",
    rating      = "rgb(255, 222, 44)",
    ratingCount = "rgb(77, 146, 255)",

    updateHuge    = "rgb(255, 120, 0)",
    updateLarge   = "rgb(255, 140, 0)",
    updateRegular = "rgb(255, 160, 0)",
    updateSmall   = "rgb(255, 180, 0)",
    updateTiny    = "rgb(255, 200, 0)",

    availabilityPreorder        = "rgb(0, 150, 255)",
    availabilityPreorderRelease = "rgb(0, 150, 255)",
    availabilityRelease         = "rgb(0, 150, 255)",
    availabilityRemoval         = lightGray,
    availabilityRerelease       = "rgb(0, 150, 255)",

    priceChangeDrop = upvote,
    priceChangeRise = downvote,

    primary         = "rgb(29, 29, 31)",
    tableBackground = "rgb(240, 240, 240)",
    lightBorder     = "rgb(0, 0, 0, 0.17)",

    roleAdmin     = green,
    roleModerator = red,
    premium       = orange
}
