import React from "react";
import {Props as ViewProps} from "../../../Components/SwiftUI/View";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {IconType, IconV} from "../../../Components/IconV";
import {Text} from "../../../Components/SwiftUI/Text";
import {MediaType, Toolbox} from "../../../Utilities/Toolbox";
import {FontWeight} from "../../../Components/SwiftUI/Enums/FontWeight";

interface Props extends ViewProps {
    user: any
}

export const UserThumbnailV: React.FC<Props> = (props) => {

    const {user} = props

    return (
        <HStack {...props}>
            <IconV src={Toolbox.mediaById(user.iconMedium, MediaType.userIcon)} iconType={IconType.user}
                   frame={{height: 30}} />
            <Text t={user.displayName}
                  font={{size: 17, weight: FontWeight.medium}}  />
        </HStack>
    )
}
