import React from "react";
import {Props as ViewProps} from "../../Components/SwiftUI/View";
import {Text} from "../../Components/SwiftUI/Text";
import {VStack} from "../../Components/SwiftUI/VStack";
import {HStack} from "../../Components/SwiftUI/HStack";
import {HorizontalAlignment} from "../../Components/SwiftUI/Enums/HorizontalAlignment";
import {FontWeight} from "../../Components/SwiftUI/Enums/FontWeight";
import {Color} from "../../Components/SwiftUI/Enums/Color";
import {Image} from "../../Components/SwiftUI/Image";
import {Spacer} from "../../Components/SwiftUI/Spacer";
import {IconV} from "../../Components/IconV";
import {Fonts} from "../../Constants/Fonts";
import {Toolbox} from "../../Utilities/Toolbox";
import {AppleImageParams} from "../../Constants/AppleImageParams";
import {LocaleStorage} from "../../Utilities/LocaleStorage";
import {NavigationLink} from "../../Components/SwiftUI/NavigationLink";
import {FontDesign} from "../../Components/SwiftUI/Enums/FontDesign";

interface Props extends ViewProps {
    deal: any
}

export const DailyDealThumbnailV: React.FC<Props> = (props) => {

    const {deal} = props

    const subtext = getSubtext(deal)
    const startDate = new Date(deal.startDate)

    function appView() {
        return (
        <NavigationLink destination={`/app/${deal.app.id}`} foregroundStyle={Color.white}>
            <HStack spacing={0}>
                <IconV src={Toolbox.getImageUrl(deal.app.artworkUrl, AppleImageParams.listIcon)}
                       frame={{height: 72}}
                       shadow={{radius: 10}} />

                <Spacer frame={{width: 10}} />

                <VStack fit alignment={HorizontalAlignment.leading} spacing={2}>
                    <Text t={deal.app.title}
                          lineLimit={2}
                          font={{size: 20, weight: FontWeight.semibold}}
                          shadow={{radius: 10}} />

                    {subtext ?
                        <Text lk={subtext}
                              font={{size: 16, weight: FontWeight.medium}}
                              shadow={{radius: 10}} />
                        : null}

                </VStack>

                <Spacer minLength={10} />

                <VStack fit spacing={0}>
                    <Text t={Toolbox.formatDateTemplate(startDate, "MMM")}
                          font={{size: 15, weight: FontWeight.semibold, design: FontDesign.rounded}}
                          shadow={{radius: 10}} />
                    <Text t={Toolbox.formatDateTemplate(startDate, "d")}
                          font={{size: 24, weight: FontWeight.bold, design: FontDesign.rounded}}
                          shadow={{radius: 10}} />
                </VStack>
            </HStack>
        </NavigationLink>
        )
    }

    return (
        <VStack stretch alignment={HorizontalAlignment.leading}
                foregroundStyle={Color.white}
                padding={{top: 10, horizontal: 16, bottom: 16}}
                background={<Image src={Toolbox.getImageUrl(deal.app.artworkUrl, AppleImageParams.listIcon)} resizeable={true} blur={100} saturation={5} brightness={-0.3} contrast={0.7} />}
                cornerRadius={20}
                shadow={{color: Color.lightBorder, radius: 4}} {...props}>
            <HStack>

                <Text t={deal.title ?? "Daily Deal"}
                      font={Fonts.title}
                      shadow={{radius: 10}} />

                <Spacer />

                <Text t={"See All"}
                      shadow={{radius: 10}} />

            </HStack>

            {appView()}

            <Text lk={deal.text}
                  shadow={{radius: 10}}
                  font={{size: 16}}
                  lineLimit={5} />

        </VStack>
    )
}

function getSubtext(deal: any, plain: boolean = false): string | null {
    let subtext: string | null = "";
    const hasSubject = deal.subject !== undefined && deal.subject !== null;
    const hasOldPrice = deal.oldPriceTier !== undefined && deal.oldPriceTier !== null;
    const hasNewPrice = deal.newPriceTier !== undefined && deal.newPriceTier !== null;

    if (hasSubject) {
        subtext += deal.subject;
    }
    if (hasSubject && hasNewPrice) {
        subtext += ": ";
    }
    if (hasOldPrice) {
        subtext += (plain
            ? LocaleStorage.shared.formatPrice(deal.oldPriceTier)
            : `~~${LocaleStorage.shared.formatPrice(deal.oldPriceTier)}~~`) + " ➤ ";
    }
    if (hasNewPrice) {
        subtext += LocaleStorage.shared.formatPrice(deal.newPriceTier) + "!";
    }

    subtext = subtext.trim()
    if (subtext.length === 0) { subtext = null }

    return subtext ?? deal.app.subtitle ?? Toolbox.formatGenres(deal.app.genres, deal.app.game);
}