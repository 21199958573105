import {gql} from "@apollo/client";

export const IAPThumbnail = gql`
fragment IAPThumbnail on InAppPurchase {
    id
    title
    priceTier
    youOwn
    lastActivity {
        ... AppActivity
    }
}
`