import React from "react";
import {Props as ViewProps, View} from "./View";
import {Color} from "./Enums/Color";

interface Props extends ViewProps {
    colors: Color[],
    startPoint: { x: number, y: number },
    endPoint: { x: number, y: number }
}

export const LinearGradient: React.FC<Props> = (props) => {

    const deltaX = props.endPoint.x - props.startPoint.x
    const deltaY = props.endPoint.y - props.startPoint.y
    const angle = (Math.PI / 2) + Math.atan2(deltaY, deltaX)

    const style: React.CSSProperties = {
        background: `linear-gradient(${angle}rad, ${props.colors[0]} 0%, ${props.colors[1]} ${(angle === (Math.PI / 2) ? props.endPoint.x : props.endPoint.y)*100}%)`,   // TODO: Calculate.
        minWidth: "100%",
        minHeight: "100%",
        alignSelf: "stretch",
        ...props.style
    }

    return (
        <View {...props} style={style} />
    )
}
