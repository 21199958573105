import {gql} from "@apollo/client";

export const GetGlobalCommentFeedQuery = gql`
query GetGlobalCommentFeed($filter: CommentFilterInput, $sort: CommentSortInput!, $page: Int!) {
    comments(filter: $filter, sort: $sort, page: $page) {
        content {
            ...CommentWithApp
        }
        hasNext
        nextPageable {
            pageNumber
        }
    }
}
`

export const GetCommentRepliesQuery = gql`
query GetCommentReplies($id: ID!, $sort: CommentSortInput!, $maxDepth: Int, $page: Int!) {
    comment(id: $id) {
        ...CommentWithApp
        replies(sort: $sort, maxDepth: $maxDepth, page: $page) {
            content {
                ...CommentThumbnail
            }
            hasNext
            nextPageable {
                pageNumber
            }
        }
    }
}
`