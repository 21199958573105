import {gql} from "@apollo/client";

export const AppThumbnail = gql`
fragment AppThumbnail on App {
    id
    title
    artworkUrl
    priceTier
    rating
    ratingCount
    preorder
    game
    arcade
    onStore
    hasInAppPurchases
    ratingInfo {
        topPosition
    }
    developer {
        name
    }
    lastActivity {
        ...AppActivity
    }
    genres {
        ...AppGenre
    }
    devices
    upvoteCount
    downvoteCount
    commentCount
    myWatch {
        ...WatchInteractionInfo
    }
    youOwn
}
`

export const AppDetail = gql`
fragment AppDetail on App {
    id
    ITunesId
    title
    artworkUrl
    assets {
        ...AppAsset
    }
    priceTier
    rating
    ratingCount
    preorder
    game
    arcade
    onStore
    hasInAppPurchases
    iapCount
    version
    lastUpdateDate
    subtitle
    releaseNotes
    description
    ratingInfo {
        topPosition
    }
    collections(sort: {by: SCORE, direction: DESC}, pageSize: 9) {
        totalElements
        content {
            ...CollectionMiniThumbnail
        }
    }
    appTags(sort: {by: APP_COUNT, direction: DESC}, page: 0) {
        totalElements
        content {
            ...AppTagThumbnail
        }
    }
    comments(sort: {by: TIMESTAMP, direction: DESC}, maxDepth: 0, pageSize: 5) {
        totalElements
        content {
            ...CommentThumbnail
        }
    }
    similarApps(pageSize: 10) {
        content {
            ...AppThumbnail
        }
    }
    developer {
        id
        name
        apps(pageSize: 9) {
            content {
                ...AppThumbnail
            }
        }
        myWatch {
            ...WatchInteractionInfo
        }
    }
    lastActivity {
        ...AppActivity
    }
    activityList {
        ...AppActivityDetail
    }
    genres {
        ...AppGenre
    }
    devices
    controller
    size
    ageRating
    releaseDate
    minimumOSVersion
    macOSCompatible
    gameCenter
    upvoteCount
    downvoteCount
    ownCount
    watchCount
    commentCount
    myVote {
        type
    }
    myWatch {
        ...WatchInteractionInfo
    }
    youOwn
}
`

export const AppGenre = gql`
fragment AppGenre on AppGenre {
    ITunesId
    title
    parentITunesId
}
`

export const AppAsset = gql`
fragment AppAsset on AppAsset {
    id
    type
    url
    device
}
`