export enum Alignment {
    center,
    leading,
    trailing,
    top,
    bottom,
    topLeading,
    topTrailing,
    bottomLeading,
    bottomTrailing
}
