import {ApolloClient, InMemoryCache} from "@apollo/client";
import {ConfigConstants} from "../Constants/ConfigConstants";
import {createFragmentRegistry} from "@apollo/client/cache";
import {AppActivity, AppActivityDetail, AppActivityRelation} from "./GraphQL/App Activity/Fragments";
import {AppAsset, AppDetail, AppGenre, AppThumbnail} from "./GraphQL/App/Fragments";
import {IAPThumbnail} from "./GraphQL/In-App Purchase/Fragments";
import {WatchInteractionInfo} from "./GraphQL/User Interaction/Fragments";
import possibleTypes from "../possibleTypes.json";
import {AppTagThumbnail} from "./GraphQL/App Tag/Fragments";
import {CommentThumbnail, CommentWithApp} from "./GraphQL/Comment/Fragments";
import {CollectionDetail, CollectionItem, CollectionMiniThumbnail} from "./GraphQL/Collection/Fragments";
import {DailyDealThumbnail} from "./GraphQL/Daily Deal/Fragments";
import {UserThumbnail} from "./GraphQL/User/Fragments";

export class Network {

    static readonly shared = new Network()

    readonly apollo = new ApolloClient({
        uri: ConfigConstants.apiUrl,
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        appsOnSale: {
                            keyArgs: ["rareOnly", "miniFilter"],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        },
                        dailyDeals: {
                            keyArgs: [],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        },
                        filterApps: {
                            keyArgs: ["appFilter", "appSort"],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        },
                        collection: {
                            keyArgs: ["id"],
                            merge: true
                        },
                        comment: {
                            keyArgs: ["id"],
                            merge: true
                        },
                        comments: {
                            keyArgs: ["filter", "sort"],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        },
                        newApps: {
                            keyArgs: ["miniFilter"],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        },
                        updatedApps: {
                            keyArgs: ["rareOnly", "miniFilter"],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        }
                    }
                },
                Collection: {
                    fields: {
                        items: {
                            keyArgs: ["types", "query"],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        },
                    },
                },
                Comment: {
                    fields: {
                        replies: {
                            keyArgs: ["sort", "maxDepth"],
                            merge(existing = {}, incoming) {
                                return {...incoming, content: [...existing.content ?? [], ...incoming.content]};
                            }
                        },
                    },
                },
            },
            fragments: createFragmentRegistry(
                AppActivity,
                AppActivityDetail,
                AppActivityRelation,
                AppAsset,
                AppDetail,
                AppGenre,
                AppTagThumbnail,
                AppThumbnail,
                CollectionDetail,
                CollectionItem,
                CollectionMiniThumbnail,
                CommentWithApp,
                CommentThumbnail,
                DailyDealThumbnail,
                IAPThumbnail,
                UserThumbnail,
                WatchInteractionInfo
            ),
            possibleTypes
        }),
    });
}