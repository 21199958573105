import React, {ReactElement} from "react";
import {Props as ViewProps} from "./View";

interface Props extends ViewProps {}

export const Group: React.FC<Props> = (props) => {
    return (
        <>
            {React.Children.map(props.children, child => {
                if (!React.isValidElement(child)) { return child }
                return React.cloneElement(child as ReactElement, {
                    ...props
                })
            })}
        </>
    );
}