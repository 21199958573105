import React from "react";
import {Props as ViewProps} from "../../Components/SwiftUI/View";
import {HStack} from "../../Components/SwiftUI/HStack";
import {ScrollView} from "../../Components/SwiftUI/ScrollView";
import {Axis} from "../../Components/SwiftUI/Enums/Axis";
import {ForEach} from "../../Components/SwiftUI/ForEach";
import {DailyDealThumbnailV} from "./DailyDealThumbnailV";
import {NavigationLink} from "../../Components/SwiftUI/NavigationLink";
import {ButtonStyle} from "../../Components/SwiftUI/Enums/ButtonStyle";

interface Props extends ViewProps {
    deals: any[]
}

export const DailyDealsCarouselV: React.FC<Props> = (props) => {
    return (
        <ScrollView axes={Axis.horizontal} showsIndicators={false} {...props}>
            <HStack padding={{horizontal: 8}} scrollTargetLayout={true}>
                <ForEach data={props.deals} id={deal => deal.id} content={deal =>
                    <NavigationLink destination={"dailydeals"} buttonStyle={ButtonStyle.plain}>
                        <DailyDealThumbnailV deal={deal}
                                             frame={{width: Math.min(window.innerWidth - 40, 380)}} />
                    </NavigationLink>
                } />
            </HStack>
        </ScrollView>
    )
}
