import {gql} from "@apollo/client";

export const GetCollectionByIdQuery = gql`
query GetCollectionById($id: ID!) {
    collection(id: $id) {
        ...CollectionDetail
    }
}
`

export const GetCollectionItemsQuery = gql`
query GetCollectionItems($id: ID!, $types: [CollectionItemType!]!, $query: String, $page: Int!) {
    collection(id: $id) {
        items(types: $types, query: $query, page: $page) {
            content {
                ...CollectionItem
            }
            hasNext
            nextPageable {
                pageNumber
            }
        }
    }
}
`