import React from "react";
import {Props as ViewProps} from "./View";
import {Group} from "./Group";

interface Props extends ViewProps {
    title?: string,
}

export const Section: React.FC<Props> = (props) => {
    return (
        <Group {...props}>{props.children}</Group>
    );
};
