import {gql} from "@apollo/client";

export const DailyDealThumbnail = gql`
fragment DailyDealThumbnail on DailyDeal {
    id
    title
    subject
    oldPriceTier
    newPriceTier
    text
    startDate
    endDate
    sponsored
    released
    app {
        id
        title
        subtitle
        artworkUrl
        ITunesId
        game
        genres {
            ...AppGenre
        }
    }
}
`