import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {ProgressView} from "./SwiftUI/ProgressView";
import {VStack} from "./SwiftUI/VStack";
import {Text} from "./SwiftUI/Text";
import {Fonts} from "../Constants/Fonts";

interface Props extends ViewProps {
    loading: boolean
    error?: Error
}

export const LoaderV: React.FC<Props> = (props) => {
    return (
        <>
            {props.loading ? <ProgressView frame={{height: 200}} /> : null}
            {props.error ?
                <VStack padding={32}>
                    <Text t={"Error"} font={Fonts.title} />
                    <Text t={props.error.message} />
                </VStack>
            : null}
        </>
    );
};
