export class ConfigConstants {

    static readonly mainUrl = "https://appraven.net"
    //static readonly mainUrl = "http://192.168.10.100:8081"
    //static readonly mainUrl = "http://192.168.1.21:8081"
    static readonly serverUrl = ConfigConstants.mainUrl + "/appraven"
    static readonly apiUrl = ConfigConstants.serverUrl + "/graphql"
    static readonly imagesUrl = ConfigConstants.serverUrl + "/images"

    static readonly appId          = 1490607195

    static readonly affiliateToken = "1000l32Lj"
    static readonly providerToken  = 120579135

    static readonly appStoreLink = `https://apps.apple.com/app/apple-store/id${ConfigConstants.appId}?pt=${ConfigConstants.providerToken}&ct=AN&mt=8`

    static readonly gameGenreId = 6014
}