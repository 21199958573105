import {gql} from "@apollo/client";

export const CollectionMiniThumbnail = gql`
fragment CollectionMiniThumbnail on Collection {
    id
    title
    topArtworks
}
`

export const CollectionDetail = gql`
fragment CollectionDetail on Collection {
    id
    title
    description
    creationDate
    public
    premiumOnly
    suggestionRestricted
    commentsRestricted
    appCount
    suggestionCount
    watchCount
    score
    user {
        id
        displayName
        iconMedium
        ...UserThumbnail
    }
    topArtworks
    comments(sort: {by: TIMESTAMP, direction: DESC}, maxDepth: 0, pageSize: 1) {
        totalElements
    }
    myVote {
        type
    }
    myWatch {
        ...WatchInteractionInfo
    }
    deleted
}
`

export const CollectionItem = gql`
fragment CollectionItem on CollectionItem {
    id
    type
    comment
    app {
        ...AppThumbnail
    }
}
`