import {Toolbox} from "../../../Utilities/Toolbox";

class ActivityData {
    id: string
    app: any
    iaps: any[]

    constructor(id: string, app: any, iaps: any[]) {
        this.id = id
        this.app = app
        this.iaps = iaps
    }
}

export class AppActivityListVM {

    groups: { day: { year: number, month: number, day: number }, data: ActivityData[] }[] = []

    insertRelations(relations: any[]): void {
        for (const relation of relations) {
            if (relation.app) {
                const app = Toolbox.copy(relation.app)
                app.lastActivity = relation
                const day = this.getDateComponents(app.lastActivity.timestamp)
                const data = new ActivityData(relation.id, app, [])
                if (this.isSameDay(day, this.getLastGroupDay())) {
                    this.groups[this.groups.length - 1].data.push(data)
                } else {
                    this.groups.push({ day, data: [data] })
                }
            } else if (relation.inAppPurchase) {
                const app = relation.inAppPurchase.app
                const iap = Toolbox.copy(relation.inAppPurchase)
                iap.lastActivity = relation
                const day = this.getDateComponents(iap.lastActivity.timestamp)
                const data = new ActivityData(relation.id, app, [iap])
                if (this.isSameDay(day, this.getLastGroupDay())) {
                    const lastData = this.groups[this.groups.length - 1].data
                    if (app.id === lastData[lastData.length - 1].app.id) {
                        lastData[lastData.length - 1].iaps.push(iap)
                    } else {
                        this.groups[this.groups.length - 1].data.push(data)
                    }
                } else {
                    this.groups.push({ day, data: [data] })
                }
            }
        }
    }

    private getDateComponents(timestamp: string): { year: number, month: number, day: number } {
        const date = new Date(timestamp)
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
    }

    private isSameDay(day1: { year: number, month: number, day: number }, day2: { year: number, month: number, day: number } | undefined): boolean {
        if (!day2) return false
        return day1.year === day2.year && day1.month === day2.month && day1.day === day2.day
    }

    private getLastGroupDay(): { year: number, month: number, day: number } | undefined {
        return this.groups.length > 0 ? this.groups[this.groups.length - 1].day : undefined
    }
}