import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {Image} from "./SwiftUI/Image";
import {Rectangle} from "./SwiftUI/Rectangle";

interface Props extends ViewProps {
    src: string,
    iconType?: IconType
}

export enum IconType {
    user,
    app
}

export const IconV: React.FC<Props> = (props) => {
    return (
        <Image {...props}
               cornerRadius={props.iconType === IconType.user ? Infinity : "21.42857143%"}
               overlay={<Rectangle border={{width: 1, color: "rgb(0, 0, 0, 0.08)"}} />} />
    );
};
