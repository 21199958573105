import React, {ReactElement, ReactNode} from "react"
import {Color} from "./Enums/Color"
import {FontWeight} from "./Enums/FontWeight"
import {TagName} from "./Enums/TagName"
import {TextAlignment} from "./Enums/TextAlignment"
import {Text} from "./Text"
import {VStack} from "./VStack"
import {HStack} from "./HStack"
import {OnAppear} from "./OnAppear"
import {Edge} from "./Enums/Edge";
import {FontDesign} from "./Enums/FontDesign";
import {Alignment} from "./Enums/Alignment";
import {ButtonStyle} from "./Enums/ButtonStyle";
import {isMobileOnly} from "react-device-detect";

export interface Props {

    frame?:
        { width?: number; height?: number; alignment?: Alignment } |
        { minWidth?: number, minHeight?: number, maxWidth?: number; maxHeight?: number; alignment?: Alignment }

    fixedSize?: boolean
    layoutPriority?: number

    padding?:
        number |
        { top?: number; leading?: number; bottom?: number, trailing?: number; horizontal?: number; vertical?: number }

    offset?: {x?: number, y?: number}

    border?: { edges?: Edge[], color: Color | string; width: number; }
    shadow?: { color?: Color | string, radius: number, x?: number, y?: number }
    cornerRadius?: number | string | { top?: number; leading?: number; bottom?: number, trailing?: number }

    font?: { name?: string, size?: number; weight?: FontWeight; design?: FontDesign }
    foregroundStyle?: Color | string
    lineLimit?: number
    multilineTextAlignment?: TextAlignment,

    navigationTitle?: string
    scrollClipDisabled?: boolean | number
    scrollTargetLayout?: boolean
    onAppear?: () => void

    blur?: number
    brightness?: number
    contrast?: number
    grayscale?: number
    hueRotation?: number
    colorInvert?: boolean
    opacity?: number
    saturation?: number

    overlay?: ReactElement | { alignment: Alignment, content?: ReactElement }
    background?: ReactElement | Color | string

    style?: React.CSSProperties
    onClick?: React.MouseEventHandler

    tagName?: TagName
    className?: string
    tag?: string | number | null

    buttonStyle?: ButtonStyle,

    children?: React.ReactNode
}

function transferPropsToChild(parentProps: Props, childProps: Props) {
    let newProps = {...childProps}
    if (parentProps.foregroundStyle) { newProps.foregroundStyle = childProps.foregroundStyle ?? parentProps.foregroundStyle }
    if (parentProps.font) { newProps.font = childProps.font ?? parentProps.font }
    if (parentProps.lineLimit) { newProps.lineLimit = childProps.lineLimit ?? parentProps.lineLimit }
    return newProps
}

export const View: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        boxSizing: "border-box",
        fontSize: 17,
        WebkitUserSelect: "none",
        ...props.style,
    }

    if (props.padding !== undefined) {
        if (typeof props.padding === "number") {
            if (props.padding < 0) {
                style.margin = props.padding
                style.width = "auto"
                style.height = "auto"
            } else {
                style.padding = props.padding
            }

        } else if ("top" in props.padding || "leading" in props.padding || "bottom" in props.padding || "trailing" in props.padding || "horizontal" in props.padding || "vertical" in props.padding) {
            const {top, leading, bottom, trailing, horizontal, vertical} = props.padding
            if (top) {
                if (top < 0) {
                    style.marginTop = top
                    style.height = "auto"
                } else {
                    style.paddingTop = top
                }
            }
            if (leading) {
                if (leading < 0) {
                    style.marginLeft = leading
                    style.width = "auto"
                } else {
                    style.paddingLeft = leading
                }
            }
            if (bottom) {
                if (bottom < 0) {
                    style.marginBottom = bottom
                    style.height = "auto"
                } else {
                    style.paddingBottom = bottom
                }
            }
            if (trailing) {
                if (trailing < 0) {
                    style.marginRight = trailing
                    style.width = "auto"
                } else {
                    style.paddingRight = trailing
                }
            }
            if (horizontal) {
                if (horizontal < 0) {
                    style.marginLeft = style.marginRight = horizontal
                    style.width = "auto"
                } else {
                    style.paddingLeft = style.paddingRight = horizontal
                }
            }
            if (vertical) {
                if (vertical < 0) {
                    style.marginTop = style.marginBottom = vertical
                    style.height = "auto"
                } else {
                    style.paddingTop = style.paddingBottom = vertical
                }
            }
        }
    }

    if (props.offset) {
        const {x, y} = props.offset
        style.transform = `translate(${x ?? 0}px, ${y ?? 0}px)`
    }

    if (props.frame !== undefined) {
        if ("width" in props.frame || "height" in props.frame) {
            const {width, height, alignment} = props.frame
            if (width) { style.width = style.minWidth = style.maxWidth = width }
            if (height) { style.height = style.minHeight = style.maxHeight = height }
            style.display = "flex"
            style.alignItems = props.style?.alignItems ?? "center"     // TODO: Alignment

        } else if ("minWidth" in props.frame || "minHeight" in props.frame || "maxWidth" in props.frame || "maxHeight" in props.frame) {
            const {minWidth, minHeight, maxWidth, maxHeight, alignment} = props.frame
            if (minWidth) { style.minWidth = minWidth }
            if (minHeight) { style.minHeight = minHeight }
            if (maxWidth) { style.maxWidth = maxWidth }
            if (maxHeight) { style.maxHeight = maxHeight }
        }
    }

    if (props.fixedSize) {
        style.maxWidth = "fit-content"
        style.maxHeight = "fit-content"
    }

    if (props.layoutPriority) {
        style.flexShrink = Math.max(0, 1-props.layoutPriority)
    }

    if (props.border !== undefined) {
        const {color, width, edges} = props.border
        const border = `${width}px solid ${color}`
        if (edges) {
            for (const edge of edges) {
                switch (edge) {
                    case Edge.top: style.borderTop = border; break
                    case Edge.leading: style.borderLeft = border; break
                    case Edge.bottom: style.borderBottom = border; break
                    case Edge.trailing: style.borderRight = border; break
                    case Edge.all: style.border = border; break
                    case Edge.horizontal: style.borderLeft = style.borderRight = border; break
                    case Edge.vertical: style.borderTop = style.borderBottom = border; break
                }
            }
        } else { style.border = border }
    }

    if (props.shadow !== undefined) {
        const {color, radius, x, y} = props.shadow
        const shadow = `${x ?? 0}px ${y ?? 0}px ${2*radius}px ${color ?? "rgb(0, 0, 0, 0.33)"}`
        if (props.tagName === TagName.span) { style.textShadow = shadow }
        else { style.boxShadow = shadow }
    }

    if (props.cornerRadius) {
        if (typeof props.cornerRadius == "string" || typeof props.cornerRadius == "number") {
            style.borderRadius = props.cornerRadius == Infinity ? 999999 : props.cornerRadius
        } else {
            let {top, leading, bottom, trailing} = props.cornerRadius
            if (top == Infinity) { top = 999999 }
            if (leading == Infinity) { leading = 999999 }
            if (bottom == Infinity) { bottom = 999999 }
            if (trailing == Infinity) { trailing = 999999 }
            style.borderRadius = `${top ?? leading ?? 0}px ${top ?? trailing ?? 0}px ${bottom ?? trailing ?? 0}px ${bottom ?? leading ?? 0}px`
        }
    }

    if (props.font) {
        style.fontFamily = props.font.name ?? props.font.design
        style.fontSize = props.font.size
        style.fontWeight = props.font.weight
    }

    if (props.foregroundStyle) {
        style.color = props.foregroundStyle
    }

    if (props.multilineTextAlignment) {
        style.textAlign = props.multilineTextAlignment
    }

    let filter = ""
    //if (props.blur !== undefined) { filter += `blur(${props.blur}px)` }
    if (props.contrast !== undefined) { filter += `contrast(${props.contrast})` }
    if (props.brightness !== undefined) { filter += `brightness(${1+props.brightness})` }
    if (props.grayscale !== undefined) { filter += `grayscale(${props.grayscale})` }
    if (props.hueRotation !== undefined) { filter += `hue-rotate(${props.hueRotation}deg)` }
    if (props.colorInvert) { filter += `invert(1.0)` }
    if (props.opacity !== undefined) { filter += `opacity(${props.opacity})` }
    if (props.saturation !== undefined) { filter += `saturate(${props.saturation})` }
    if (filter.length !== 0) { style.filter = filter }

    if (typeof props.background === "string") {
        style.background = props.background
    }

    let children: ReactNode = React.Children.map(props.children, child => {
        if (!React.isValidElement(child)) { return child }
        return React.cloneElement(child as ReactElement, transferPropsToChild(props, child.props))
    })

    if (props.scrollClipDisabled) {
        const margin = (typeof props.scrollClipDisabled === "number") ? props.scrollClipDisabled : 16
        style.margin = `-16px -${margin}px`
        style.width = `calc(100% + ${2*margin}px)`
        children =
            <div style={{width: "max-content", paddingTop: 16, paddingBottom: 16}}>
                {children}
            </div>
    }

    if (props.scrollTargetLayout) {
        children = React.Children.map(children, child => {
            if (!React.isValidElement(child)) { return child }
            return React.cloneElement(child as ReactElement, {
                style: {...child.props.style, scrollSnapAlign: "start", scrollMarginLeft: 16}
            })
        })
    }

    const allProps = {className: props.className, style, children: children}
    let element: ReactElement = React.createElement(props.tagName ?? "div", allProps)

    if (props.blur !== undefined) {

        const blurEffect: React.CSSProperties = {
            position: "absolute",
            inset: 0,
            WebkitBackdropFilter: `blur(${props.blur}px)`,
            backdropFilter: `blur(${props.blur}px)`,
            pointerEvents: "none"
        }

        element =
            <div style={{position: "relative", width: "100%", height: "100%"}}>
                {element}
                <div style={{...blurEffect, borderRadius: style.borderRadius}} />
            </div>
    }

    if (props.overlay && (React.isValidElement(props.overlay) || props.overlay.content)) {
        let overlay: ReactElement, alignment: Alignment

        if (React.isValidElement(props.overlay)) {
            overlay = props.overlay
            alignment = Alignment.center
        } else {
            overlay = props.overlay.content!
            alignment = props.overlay.alignment
        }

        let alignmentStyle: React.CSSProperties
        switch (alignment) {
            case Alignment.center: alignmentStyle = {left: "50%", top: "50%", transform: "translateX(-50%) translateY(-50%)"}; break
            case Alignment.leading: alignmentStyle = {left: 0, top: "50%", transform: "translateY(-50%)"}; break
            case Alignment.trailing: alignmentStyle = {right: 0, top: "50%", transform: "translateY(-50%)"}; break
            case Alignment.top: alignmentStyle = {top: 0, left: "50%", transform: "translateX(-50%)"}; break
            case Alignment.bottom: alignmentStyle = {bottom: 0, left: "50%", transform: "translateX(-50%)"}; break
            case Alignment.topLeading: alignmentStyle = {top: 0, left: 0}; break
            case Alignment.topTrailing: alignmentStyle = {top: 0, right: 0}; break
            case Alignment.bottomLeading: alignmentStyle = {bottom: 0, left: 0}; break
            case Alignment.bottomTrailing: alignmentStyle = {bottom: 0, right: 0}; break
        }

        element =
            <div style={{display: "flex", position: "relative", flexShrink: style.flexShrink, textAlign: style.textAlign, maxWidth: style.maxWidth, maxHeight: style.maxHeight, aspectRatio: style.aspectRatio}}>
                {element}
                <div style={{position: "absolute", width: "100%", height: "100%", zIndex: 1}}>
                    {React.cloneElement(overlay as ReactElement, {
                        ...transferPropsToChild(props, overlay.props),
                        style: {position: "absolute", borderRadius: style.borderRadius, ...alignmentStyle}
                    })}
                </div>
            </div>
    }

    if (React.isValidElement(props.background)) {
        style.zIndex = 0
        element =
            <div style={{display: "flex", position: "relative", alignSelf: "stretch", flexShrink: style.flexShrink, textAlign: style.textAlign, maxWidth: style.maxWidth, maxHeight: style.maxHeight, aspectRatio: style.aspectRatio}}>
                <div style={{position: "absolute", inset: 0}}>
                    {React.cloneElement(props.background as ReactElement, {
                        style: {width: "100%", height: "100%", borderRadius: style.borderRadius}
                    })}
                </div>
                {element}
            </div>
    }

    if (props.navigationTitle) {
        element =
            <VStack spacing={0} padding={{top: isMobileOnly ? 0 : 8}}>
                <HStack padding={{horizontal: 16}}>
                    <Text t={props.navigationTitle} font={{size: 32, weight: FontWeight.bold}} />
                </HStack>
                {element}
            </VStack>
    }

    if (props.onAppear) {
        element =
            <OnAppear action={props.onAppear}>{element}</OnAppear>
    }

    return element
}
