import React from "react";
import {Props as ViewProps} from "../../Components/SwiftUI/View";
import {HStack} from "../../Components/SwiftUI/HStack";
import {Text} from "../../Components/SwiftUI/Text";
import {Image} from "../../Components/SwiftUI/Image";
import {Color} from "../../Components/SwiftUI/Enums/Color";
import {NavigationLink} from "../../Components/SwiftUI/NavigationLink";

const icon = require("../../Assets/RavenLogo.png");

interface Props extends ViewProps {}

export const Header: React.FC<Props> = (props) => {
    return (
        <NavigationLink destination={"/"} {...props}>
            <HStack spacing={4}>
                <Image src={icon}
                       frame={{height: 48}} />
                <Text t={"AppRaven.net"}
                      font={{name: "Futura", size: 30}}
                      foregroundStyle={Color.tint} />
            </HStack>
        </NavigationLink>
    );
};
