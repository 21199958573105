export class UnitPoint {
    static readonly zero = {x: 0, y: 0}
    static readonly center = {x: 0.5, y: 0.5}
    static readonly leading = {x: 0, y: 0.5}
    static readonly trailing = {x: 1, y: 0.5}
    static readonly top = {x: 0.5, y: 0}
    static readonly bottom = {x: 0.5, y: 1}
    static readonly topLeading = {x: 0, y: 0}
    static readonly topTrailing = {x: 1, y: 0}
    static readonly bottomLeading = {x: 0, y: 1}
    static readonly bottomTrailing = {x: 1, y: 1}
}