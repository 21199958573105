import {gql} from "@apollo/client";

export const AppActivity = gql`
fragment AppActivity on AppActivity {
    timestamp
    ... on AppActivityAvailability {
        availabilityChangeType: type
    }
    ... on AppActivityPriceChange {
        priceChangeType: type
        priceTierFrom
    }
    ... on AppActivityUpdate {
        updateSize
        versionTo
    }
}
`

export const AppActivityDetail = gql`
fragment AppActivityDetail on AppActivity {
    id
    timestamp
    ... on AppActivityAvailability {
        availabilityChangeType: type
        priceTier
    }
    ... on AppActivityPriceChange {
        priceChangeType: type
        priceTierFrom
        priceTierTo
    }
    ... on AppActivityUpdate {
        updateSize
        versionFrom
        versionTo
    }
}
`

export const AppActivityRelation = gql`
fragment AppActivityRelation on AppActivity {
    id
    app {
        ...AppThumbnail
    }
    inAppPurchase {
        ...IAPThumbnail
        app {
            ...AppThumbnail
        }
    }
    ...AppActivity
}
`