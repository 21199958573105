import {gql} from "@apollo/client";

export const GetHomeContentQuery = gql`
query GetHomeContent($genreId: Int, $excludedGenreId: Int, $includedGenreIds: [Int!], $excludedGenreIds: [Int!], $appType: AppType, $pageSize: Int!) {
    dailyDeals(page: 0) {
        content {
            ...DailyDealThumbnail
        }
    }
    popularApps: filterApps(appFilter: {includedGenreIds: $includedGenreIds, excludedGenreIds: $excludedGenreIds}, appSort: {by:POPULARITY_DAY}, pageSize: $pageSize) {
        content {
            ...AppThumbnail
        }
    }
    appsOnSale: appsOnSale(rareOnly: true, miniFilter: {ratingCount: 10, genreId: $genreId, excludedGenreId: $excludedGenreId}, pageSize: $pageSize) {
        content {
            ...AppActivityRelation
        }
    }
    appsWithIAPsOnSale: IAPsOnSalePreview(miniFilter: {ratingCount: 10, genreId: $genreId, excludedGenreId: $excludedGenreId}, pageSize: $pageSize)
    newestApps: newApps(miniFilter: {ratingCount: 0, genreId: $genreId, excludedGenreId: $excludedGenreId}, pageSize: $pageSize) {
        content {
            ...AppActivityRelation
        }
    }
    updatedApps: updatedApps(rareOnly: true, miniFilter: {ratingCount: 100, genreId: $genreId, excludedGenreId: $excludedGenreId}, pageSize: $pageSize) {
        content {
            ...AppActivityRelation
        }
    }
    preorders: filterApps(appFilter: {preorder:true, includedGenreIds: $includedGenreIds, excludedGenreIds: $excludedGenreIds}, appSort: {by:ADDED_DATE}, pageSize: $pageSize) {
        content {
            ...AppThumbnail
        }
    }
    popularCollections: collections(filter: {appType: $appType}, sort: {by:POPULARITY_DAY}, pageSize: $pageSize) {
        content {
            ...CollectionMiniThumbnail
        }
    }
}
`

export const GetAppDetailQuery = gql`
query GetAppDetail($id: ID!) {
    app(id: $id) {
        ...AppDetail
    }
}
`

export const FilterAppsQuery = gql`
query FilterApps($appFilter: AppFilterInput!, $appSort: AppSortInput!, $page: Int!) {
    filterApps(appFilter: $appFilter, appSort: $appSort, page: $page) {
        content {
            ...AppThumbnail
        }
        hasNext
        nextPageable {
            pageNumber
        }
    }
}
`