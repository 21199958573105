import React, {useState} from "react";
import {Props as ViewProps, View} from "./View";
import {ContentMode} from "./Enums/ContentMode";
import {isSafari} from "react-device-detect";
import {Color} from "./Enums/Color";

interface Props extends ViewProps {
    src?: string
    systemName?: string,
    resizeable?: boolean
    contentMode?: ContentMode
    onLoad?: (image: HTMLImageElement) => void
}

export const Image: React.FC<Props> = (props) => {

    const [aspectRatio, setAspectRatio] = useState<number>(isSafari ? 0 : 1)

    const isSystem = props.systemName != null

    const style: React.CSSProperties = {
        flexShrink: 0,
        overflow: "hidden",
        width: "fit-content",       // Works only in Safari
        height: "fit-content",      // Works only in Safari
        aspectRatio: aspectRatio,   // For non-Safari browsers
        maxWidth: "100%",           // For non-Safari browsers
        maxHeight: "100%",          // For non-Safari browsers
        background: isSystem ? props.foregroundStyle ?? Color.tint : undefined,
        mask: isSystem ? `url(${props.systemName}) center / contain no-repeat` : undefined,
        WebkitMask: isSystem ? `url(${props.systemName}) center / contain no-repeat` : undefined,
        ...props.style
    }

    const imageStyle: React.CSSProperties = {
        display: "block",
        width: props.resizeable ? "100%" : undefined,
        height: props.resizeable ? "100%" : undefined,
        objectFit: props.contentMode ?? undefined,
        maxWidth: "100%",
        maxHeight: "100%",
        pointerEvents: "none",
        opacity: isSystem ? 0 : undefined
    }

    function onLoad(img: HTMLImageElement) {
        if (props.onLoad) { props.onLoad(img) }
        if (!isSafari) { setAspectRatio(img.naturalWidth / img.naturalHeight) }
    }

    return (
        <View {...props} style={style}>
            <img src={props.src ?? props.systemName} style={imageStyle} loading={"lazy"}
                 onLoad={event => onLoad(event.target as HTMLImageElement)} />
        </View>
    );
};
