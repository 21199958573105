import {gql} from "@apollo/client";

export const AppTagThumbnail = gql`
fragment AppTagThumbnail on AppTag {
    id
    title
    description
    appCount
    watchCount
    myWatch {
        ...WatchInteractionInfo
    }
}
`