import {gql} from "@apollo/client";

export const GetAppsOnSaleQuery = gql`
query GetAppsOnSale($rareOnly: Boolean!, $miniFilter: MiniFilterInput!, $page: Int!, $pageSize: Int!) {
    appsOnSale(rareOnly: $rareOnly, miniFilter: $miniFilter, page: $page, pageSize: $pageSize) {
        content {
            ...AppActivityRelation
        }
        hasNext
        nextPageable {
            pageNumber
        }
    }
}
`

export const GetNewAppsQuery = gql`
query GetNewApps($miniFilter: MiniFilterInput!, $page: Int!) {
    newApps(miniFilter: $miniFilter, page: $page) {
        content {
            ...AppActivityRelation
        }
        hasNext
        nextPageable {
            pageNumber
        }
    }
}
`

export const GetUpdatedAppsQuery = gql`
query GetUpdatedApps($rareOnly: Boolean!, $miniFilter: MiniFilterInput!, $page: Int!) {
    updatedApps(rareOnly: $rareOnly, miniFilter: $miniFilter, page: $page) {
        content {
            ...AppActivityRelation
        }
        hasNext
        nextPageable {
            pageNumber
        }
    }
}
`