import React, {ReactElement} from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {HStack} from "./SwiftUI/HStack";
import {VStack} from "./SwiftUI/VStack";
import {HorizontalAlignment} from "./SwiftUI/Enums/HorizontalAlignment";
import {Text} from "./SwiftUI/Text";
import {Color} from "./SwiftUI/Enums/Color";
import {FontWeight} from "./SwiftUI/Enums/FontWeight";
import {RatingStarsV} from "./RatingStarsV";
import {Spacer} from "./SwiftUI/Spacer";
import {IconV} from "./IconV";
import {AppleImageParams} from "../Constants/AppleImageParams";
import {Toolbox} from "../Utilities/Toolbox";
import {FontDesign} from "./SwiftUI/Enums/FontDesign";
import {Image} from "./SwiftUI/Image";
import {Alignment} from "./SwiftUI/Enums/Alignment";

const plusSymbol = require("../Assets/plus@3x.png")

interface Props extends ViewProps {
    app: any
}

export const AppThumbnailV: React.FC<Props> = (props) => {

    const {app} = props

    const [activityColor, activityLabel] = Toolbox.activityInfo(app.lastActivity)

    function votesLabel(): ReactElement | null {
        const score = app.upvoteCount - app.downvoteCount
        let showsScore = app.upvoteCount !== 0 || app.downvoteCount !== 0
        if (showsScore) {
            return <Text t={Toolbox.formatNumber(score, true)}
                         font={{size: 11, weight: FontWeight.medium}}
                         foregroundStyle={score > 0 ? Color.upvote : score < 0 ? Color.downvote : Color.lightGray} />
        } else {
            return null
        }
    }

    return (
        <HStack {...props}>

            <IconV src={Toolbox.getImageUrl(app.artworkUrl, AppleImageParams.listIcon)} />

            <VStack stretch fit alignment={HorizontalAlignment.leading} spacing={0}>

                <Text t={Toolbox.formatGenres(app.genres, app.game)}
                      foregroundStyle={Color.lightGray}
                      font={{size: 11, weight: FontWeight.medium, design: FontDesign.rounded}} />

                <Text t={app.title}
                      font={{size: 16, weight: FontWeight.medium}}
                      foregroundStyle={Color.primary}
                      lineLimit={1} />

                <Spacer frame={{height: 1}} />

                <HStack spacing={0}>
                    <RatingStarsV rating={app.rating} frame={{height: 12}} />

                    <Spacer frame={{width: 2}} />

                    {app.ratingCount > 0 ?
                        <Text t={Toolbox.formatNumber(app.ratingCount)}
                          foregroundStyle={Color.ratingCount}
                          font={{size: 11, weight: FontWeight.medium}} />
                    : null}

                    <Spacer frame={{maxWidth: 16}} />

                    <HStack fit spacing={6} padding={{horizontal: 6}} background={Color.gray6} cornerRadius={6}>
                        {votesLabel()}
                        {app.commentCount > 0 ?
                            <Text t={Toolbox.formatNumber(app.commentCount)}
                                  font={{size: 11, weight: FontWeight.medium}}
                                  foregroundStyle={Color.comment} />
                        : null}
                    </HStack>

                </HStack>

            </VStack>

            <Spacer />

            <VStack spacing={0}>

                <Text lk={app.preorder ? "Pre-Order" : activityLabel}
                      font={{size: 13}}
                      foregroundStyle={"rgb(191, 191, 191)"} />
                
                <Text t={Toolbox.appPriceLabel(app)}
                      font={{size: 16, weight: FontWeight.semibold}}
                      foregroundStyle={Color.white}
                      padding={{horizontal: 8, vertical: 3.2}}
                      frame={{minWidth: 62}}
                      background={activityColor}
                      cornerRadius={8}
                      overlay={{alignment: Alignment.topTrailing, content:
                          app.hasInAppPurchases ?
                              <Image src={plusSymbol} frame={{height: 8}} offset={{x: -2, y: 3}} />
                          : undefined
                      }}
                />

                <Text t={Toolbox.formatDeviceSupport(app.devices)}
                      font={{size: 13}}
                      foregroundStyle={Color.lightGray} />

            </VStack>
        </HStack>
    );
};
