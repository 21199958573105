import React, {ReactElement, SetStateAction} from "react"
import {Props as ViewProps, View} from "../../../Components/SwiftUI/View"
import {VStack} from "../../../Components/SwiftUI/VStack"
import {ForEach} from "../../../Components/SwiftUI/ForEach"
import {AppThumbnailV} from "../../../Components/AppThumbnailV"
import {DocumentNode, useQuery} from "@apollo/client"
import {
    GetAppsOnSaleQuery,
    GetNewAppsQuery,
    GetUpdatedAppsQuery
} from "../../../Server API/GraphQL/App Activity/Queries"
import {Text} from "../../../Components/SwiftUI/Text"
import {Toolbox} from "../../../Utilities/Toolbox"
import {HorizontalAlignment} from "../../../Components/SwiftUI/Enums/HorizontalAlignment"
import {Color} from "../../../Components/SwiftUI/Enums/Color"
import {Fonts} from "../../../Constants/Fonts"
import {Picker} from "../../../Components/SwiftUI/Picker";
import {LoaderV} from "../../../Components/LoaderV";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {FontWeight} from "../../../Components/SwiftUI/Enums/FontWeight";
import {Device} from "../../../Model/Device";
import {ScrollView} from "../../../Components/SwiftUI/ScrollView";
import {Axis} from "../../../Components/SwiftUI/Enums/Axis";
import {AppGenreStorage} from "../../../Utilities/AppGenreStorage";
import {Section} from "../../../Components/SwiftUI/Section";
import {NavigationLink} from "../../../Components/SwiftUI/NavigationLink";
import {useStoredState} from "../../../Utilities/StoredState";
import {AppActivityListVM} from "./AppActivityListVM";
import {useParams} from "react-router-dom";
import {Divider} from "../../../Components/SwiftUI/Divider";

interface Props extends ViewProps {}

export const AppActivityListV: React.FC<Props> = (props) => {

    const { type } = useParams<{ type: string }>()

    let title: string, query: DocumentNode, dataPath: string, initialRatingCount = 10
    switch (type) {
        case "pricedrops":
            title = "On Sale!"
            query = GetAppsOnSaleQuery
            dataPath = "appsOnSale"
            break
        case "new":
            title = "New Releases"
            query = GetNewAppsQuery
            dataPath = "newApps"
            initialRatingCount = 0
            break
        default:
            title = "Recently Updated"
            query = GetUpdatedAppsQuery
            dataPath = "updatedApps"
            break
    }

    const model = new AppActivityListVM()

    const [category, setCategory] = useStoredState("category", null)
    const [price, setPrice] = useStoredState("price", 'ANY')
    const [ratingCount, setRatingCount] = useStoredState("ratingCount", initialRatingCount)
    const [device, setDevice] = useStoredState("device", null)

    const { loading, error, data, fetchMore } = useQuery(query, {
        variables: {
            rareOnly: true,
            miniFilter: {
                genreId: category,
                price: price,
                ratingCount: ratingCount,
                device: device
            },
            page: 0,
            pageSize: 19
        }
    })

    function loadData() {
        fetchMore({ variables: {
            page: data[dataPath].nextPageable.pageNumber
        }})
    }

    if (data) {
        model.insertRelations(data[dataPath].content)
    }

    function filterPicker<T extends string | number | null>(title: string, selection: [T, React.Dispatch<SetStateAction<T>>], content: ReactElement): ReactElement {
        return (
            <VStack spacing={0}>

                <Text t={title}
                      font={{size: 13, weight: FontWeight.semibold}}
                      foregroundStyle={Color.gray} />

                <Picker selection={selection}>
                    {content}
                </Picker>

            </VStack>
        )
    }

    function filter(): ReactElement {
        return (
                <ScrollView axes={Axis.horizontal} showsIndicators={false}
                            padding={{vertical: 10}}
                            background={Color.white}
                            cornerRadius={{bottom: 14}}
                            shadow={{radius: 2, y: 4, color: "rgb(0, 0, 0, 0.08)"}}
                >
                    <View padding={{horizontal: 16}}>
                        <HStack background={Color.tableBackground} padding={{horizontal: 16, vertical: 8}} cornerRadius={10}>
                            {filterPicker("Category", [category, setCategory],
                                <>
                                    <Text t={"Any"} tag={null} />
                                    <Section title={"App Categories"}>
                                        <ForEach data={AppGenreStorage.getAllGenres(false)} id={genre => genre.ITunesId} content={genre =>
                                            <Text t={genre.title} tag={genre.ITunesId} />
                                        } />
                                    </Section>
                                    <Section title={"Game Categories"}>
                                        <ForEach data={AppGenreStorage.getAllGenres(true)} id={genre => genre.ITunesId} content={genre =>
                                            <Text t={genre.title} tag={genre.ITunesId} />
                                        } />
                                    </Section>
                                </>
                            )}

                            <Divider />

                            {filterPicker("Price", [price, setPrice],
                                <>
                                    <Text t={"Any"} tag={"ANY"} />
                                    <Text t={"Free"} tag={"FREE"} />
                                    <Text t={"Paid"} tag={"PAID"} />
                                </>
                            )}

                            <Divider />

                            {filterPicker("Ratings", [ratingCount, setRatingCount],
                                <ForEach data={[0, 10, 50, 100, 500, 1000, 10000]} id={count => count} content={count =>
                                    <Text t={count === 0 ? "Any" : `${count}+`} tag={count} />
                                } />
                            )}

                            <Divider />

                            {filterPicker("Device", [device, setDevice],
                                <>
                                    <Text t={"Any"} tag={null} />
                                    <ForEach data={Device.sortedAllCases} id={device => device} content={device =>
                                        <Text t={Device.title(device)} tag={device} />
                                    } />
                                </>
                            )}
                        </HStack>
                    </View>
                </ScrollView>
        )
    }

    return (
        <VStack stretch spacing={32} navigationTitle={title} background={Color.groupedBackground}>

            {filter()}

            <ForEach data={model.groups} id={group => JSON.stringify(group.day)} content={group =>
                <VStack stretch spacing={6} alignment={HorizontalAlignment.leading}>

                    <Text t={Toolbox.formatDate(Toolbox.dateFromComponents(group.day), false)}
                          font={Fonts.title}
                          foregroundStyle={Color.gray}
                          padding={{leading: 16}} />

                    <VStack stretch spacing={0} background={Color.white} cornerRadius={14}>
                        <ForEach data={group.data} id={data => data.id} content={data =>
                            <NavigationLink destination={`/app/${data.app.id}`} padding={{horizontal: 12, vertical: 6}}>
                                <AppThumbnailV app={data.app} frame={{height: 62}} />
                            </NavigationLink>
                        }/>
                    </VStack>
                </VStack>
            } />

            <LoaderV loading={loading} error={error} />
            {data?.[dataPath].hasNext ? <View onAppear={loadData} /> : null}
        </VStack>
    )
}