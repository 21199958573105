import React from "react"
import {useQuery} from "@apollo/client"
import {Props as ViewProps, View} from "../../Components/SwiftUI/View";
import {GetDailyDealsQuery} from "../../Server API/GraphQL/Daily Deal/Queries";
import {DailyDealThumbnailV} from "./DailyDealThumbnailV";
import {VStack} from "../../Components/SwiftUI/VStack";
import {ForEach} from "../../Components/SwiftUI/ForEach";
import {LoaderV} from "../../Components/LoaderV";

interface Props extends ViewProps {}

export const DailyDealsV: React.FC<Props> = (props) => {

    const { loading, error, data, fetchMore } = useQuery(GetDailyDealsQuery, {
        variables: {
            page: 0
        }
    })

    function loadData() {
        fetchMore({ variables: {
            page: data.dailyDeals.nextPageable.pageNumber
        }})
    }

    const deals: any[] = data?.dailyDeals.content ?? []

    return (
        <VStack spacing={10} navigationTitle={"Daily Deals"} padding={{top: 8, horizontal: 10}}>

            <ForEach data={deals} id={deal => deal.id} content={deal =>
                <DailyDealThumbnailV deal={deal} />
            } />

            <LoaderV loading={loading} error={error} />
            {data?.dailyDeals.hasNext ? <View onAppear={loadData} /> : null}
        </VStack>
    )
}