import React from "react";
import {Props as ViewProps, View} from "./View";
import {TagName} from "./Enums/TagName";
import Markdown from 'markdown-to-jsx'

interface Props extends ViewProps {
    t?: string,
    lk?: string,
}

export const Text: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        whiteSpace: "pre-wrap",
        flexShrink: 0,
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...props.style
    }

    if (props.lineLimit) {
        style.display = "-webkit-box"
        style.WebkitBoxOrient = "vertical"
        style.WebkitLineClamp = props.lineLimit
        style.lineClamp = props.lineLimit
        style.overflow = "hidden"
        if (props.lineLimit === 1) { style.textAlign = "left" }
    }

    return (
        <View tagName={TagName.span} {...props} style={style}>
            {props.t ? props.t : null}
            {props.lk ? <Markdown>{props.lk}</Markdown> : null}
        </View>
    )
}
