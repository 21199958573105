export class AppDetailVM {

    async fetchAdditionalInfo(iTunesId: number): Promise<string | null> {
        const countryCode = "US"
        const languageCode = navigator.language.split('-')[0] || "en"

        const url = `https://itunes.apple.com/${countryCode}/lookup?id=${iTunesId}&l=${languageCode}`

        let response
        try {
            response = await fetch(url)
        } catch (error) {
            console.error('Error fetching additional info:', error)
            return null
        }

        if (!response.ok) {
            console.error('Failed to fetch additional info:', response.statusText)
            return null
        }

        const data = await response.json()
        const result = data.results?.[0]

        if (!result) {
            console.warn('No result found for the additional info request')
            return null
        }

        return result.description
    }

}