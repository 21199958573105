import React from "react";
import {Props as ViewProps, View} from "./View";
import {Color} from "./Enums/Color";

interface Props extends ViewProps {}

export const Divider: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        background: Color.gray,
        opacity: 0.5,
        minWidth: 0.35,
        minHeight: 0.35,
        alignSelf: "stretch",
        ...props.style
    }

    return (
        <View {...props} style={style} />
    )
}
