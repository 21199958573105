import React from "react";
import {Props as ViewProps, View} from "./View";

interface Props extends ViewProps {}

export const Rectangle: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        width: "100%",
        height: "100%",
        ...props.style
    }

    return (
        <View {...props} style={style} />
    )
}
