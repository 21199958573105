import {gql} from "@apollo/client";

export const CommentWithApp = gql`
fragment CommentWithApp on Comment {
    id
    text
    type
    timestamp
    user {
        id
        displayName
        iconSmall
        premium
        role
    }
    premiumOnly
    entity {
        id
        ... on App {
            ...AppThumbnail
        }
    }
    parent {
        id
        user {
            id
            displayName
        }
    }
    upvoteCount
    downvoteCount
    myVote {
        type
    }
    hasReplies
    replyCount
    commentCount
    deleted
}
`

export const CommentThumbnail = gql`
fragment CommentThumbnail on Comment {
    id
    text
    timestamp
    user {
        id
        displayName
        iconSmall
        premium
        role
    }
    premiumOnly
    entity {
        id
    }
    parent {
        id
        user {
            id
            displayName
        }
    }
    upvoteCount
    downvoteCount
    myVote {
        type
    }
    hasReplies
    replyCount
    commentCount
    deleted
}
`