import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {HStack} from "./SwiftUI/HStack";
import {Color} from "./SwiftUI/Enums/Color";
import {Spacer} from "./SwiftUI/Spacer";
import {Image} from "./SwiftUI/Image";
import {Text} from "./SwiftUI/Text";
import {Toolbox} from "../Utilities/Toolbox";
import {FontWeight} from "./SwiftUI/Enums/FontWeight";
import {FontDesign} from "./SwiftUI/Enums/FontDesign";

interface Props extends ViewProps {
    icon: string, count: number
}

export const ActionButton: React.FC<Props> = (props) => {
    return (
        <HStack spacing={0} frame={{height: 40}} padding={{horizontal: 8}} background={Color.tableBackground} cornerRadius={8} {...props}>
            <Spacer minLength={8} />
            <Image src={props.icon} frame={{height: 21}}/>
            <Spacer frame={{width: 12}} />
            <Text t={Toolbox.formatNumber(props.count)}
                  font={{size: 21, weight: FontWeight.semibold, design: FontDesign.rounded}} />
            <Spacer minLength={8} />
        </HStack>
    )
}
