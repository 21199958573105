import React, {ReactElement, useEffect} from "react"
import {Props as ViewProps, View} from "../../../Components/SwiftUI/View"
import {VStack} from "../../../Components/SwiftUI/VStack"
import {ForEach} from "../../../Components/SwiftUI/ForEach"
import {useQuery} from "@apollo/client"
import {Color} from "../../../Components/SwiftUI/Enums/Color"
import {LoaderV} from "../../../Components/LoaderV";
import {useParams} from "react-router-dom";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {Text} from "../../../Components/SwiftUI/Text";
import {Axis} from "../../../Components/SwiftUI/Enums/Axis";
import {ScrollView} from "../../../Components/SwiftUI/ScrollView";
import {useStoredState} from "../../../Utilities/StoredState";
import {Picker} from "../../../Components/SwiftUI/Picker";
import {GetCommentRepliesQuery, GetGlobalCommentFeedQuery} from "../../../Server API/GraphQL/Comment/Queries";
import {CommentThumbnailV} from "./CommentThumbnailV";

interface Props extends ViewProps {}

export const CommentsV: React.FC<Props> = (props) => {

    const { id } = useParams<{ id: string }>()

    const [appType, setAppType] = useStoredState<string | null>("appType", null)
    const [commentType, setCommentType] = useStoredState<string | null>("commentType", "POSITIVE_REVIEW")
    const [sortBy, setSortBy] = useStoredState<string | null>("sort", "TIMESTAMP")
    const [sortDirection, setSortDirection] = useStoredState<string | null>("direction", id ? "ASC" : "DESC")

    const query = id ? GetCommentRepliesQuery : GetGlobalCommentFeedQuery

    const { loading, error, data, fetchMore } = useQuery(query, {
        variables: {
            id: id,
            filter: {appType: appType, commentTypes: [commentType]},
            sort: {by: sortBy, direction: sortDirection},
            page: 0
        }
    })

    function loadData() {
        fetchMore({ variables: {
            page: (id ? data.comment.replies : data.comments).nextPageable.pageNumber
        }})
    }

    let title = id ? (data ? `Replies to ${data?.comment.user.displayName}` : "Replies") : "Reviews"
    const comments: any[] = (id ? data?.comment.replies : data?.comments)?.content ?? []

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    function filter(): ReactElement {
        return (
            <ScrollView axes={Axis.horizontal} showsIndicators={false}
                        padding={{vertical: 10}}
            >
                <HStack padding={{horizontal: 16}}>
                    <Picker selection={[appType, setAppType]} frame={{height: 38}} padding={{horizontal: 4}} background={Color.tableBackground} cornerRadius={10}>
                        <Text t={"Apps & Games"} tag={null} />
                        <Text t={"Apps"} tag={"APP"} />
                        <Text t={"Games"} tag={"GAME"} />
                    </Picker>
                </HStack>
            </ScrollView>
        )
    }

    return (
        <VStack stretch spacing={0} navigationTitle={title}>

            {!id && filter()}

            <VStack stretch spacing={10} padding={{top: 8, horizontal: 10, bottom: 10}}>

                {data?.comment &&
                    <CommentThumbnailV comment={data.comment} showEntity={data.comment.parent == null} showReplyCount={false} />
                }

                <ForEach data={comments} id={comment => comment.id} content={comment =>
                    <HStack padding={{leading: comment.parent ? 30 : 0}}>
                        <CommentThumbnailV comment={comment} showEntity={id == null} />
                    </HStack>
                } />
            </VStack>

            <LoaderV loading={loading} error={error} />
            {(id ? data?.comment.replies : data?.comments)?.hasNext ? <View onAppear={loadData} /> : null}
        </VStack>
    )
}