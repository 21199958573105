import React, {useState} from "react"
import {Props as ViewProps} from "../../../Components/SwiftUI/View"
import {ScrollView} from "../../../Components/SwiftUI/ScrollView";
import {Axis} from "../../../Components/SwiftUI/Enums/Axis";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {ForEach} from "../../../Components/SwiftUI/ForEach";
import {Image} from "../../../Components/SwiftUI/Image";
import {Toolbox} from "../../../Utilities/Toolbox";
import {AppleImageParams} from "../../../Constants/AppleImageParams";
import {Color} from "../../../Components/SwiftUI/Enums/Color";

interface Props extends ViewProps {
    screenshots: any[]
}

export const AppDetailScreenshotsV: React.FC<Props> = (props) => {

    const maxWidth = Math.min(window.innerWidth - 32, 500)

    const screenshots = props.screenshots

    const [ratios, setRatios] = useState<Record<string, number>>({});

    function addRatio(key: string, value: number) {
        setRatios((prevRatios) => ({
            ...prevRatios,
            [key]: value,
        }));
    }

    const firstRatio = ratios[screenshots[0].url]
    const height = screenshots ? Math.min(maxWidth / firstRatio, 500) : 150

    function width(url: string) {
        return height * (ratios[url] ?? firstRatio ?? 2)
    }

    return (
        <ScrollView axes={Axis.horizontal} showsIndicators={false} scrollClipDisabled>
            <HStack scrollTargetLayout={true} padding={{horizontal: 16}}>
                <ForEach data={screenshots} id={screenshot=>screenshot.id} content={screenshot =>
                    <Image src={Toolbox.getImageUrl(screenshot.url, AppleImageParams.screenshot)}
                           frame={{width: width(screenshot.url), height: height}}
                           cornerRadius={10}
                           shadow={{color: Color.lightBorder, radius: 4}}
                           onLoad={image => { addRatio(screenshot.url, image.naturalWidth / image.naturalHeight) }} />
                } />
            </HStack>
        </ScrollView>
    )
}