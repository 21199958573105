import {gql} from "@apollo/client";

export const WatchInteractionInfo = gql`
fragment WatchInteractionInfo on WatchInteraction {
    price
    inAppPurchases
    maxPriceTier
    rareOnly
    updates
    availability
    comments
    newAppAdded
    notificationLevel
}
`