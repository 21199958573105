import React, {ReactElement} from "react";
import {Props as ViewProps} from "./View";
import {useInView} from "react-intersection-observer";

interface Props extends ViewProps {
    action: () => void
}

export const OnAppear: React.FC<Props> = (props) => {

    const { ref } = useInView({
        onChange: (visible) => {
            if (visible) {
                props.action()
            }
        }
    });

    return (
        <>
            {React.Children.map(props.children, child => {
                if (!React.isValidElement(child)) { return child }
                return React.cloneElement(child as ReactElement, {
                    ref: ref
                })
            })}
        </>
    );
};
