export class PriceTerritory {
    static readonly default = {
        isoCode: "US",
        title: "United States",
        currencyCode: "USD",
        pricePoints: [
            0.0,
            0.29,
            0.39,
            0.49,
            0.59,
            0.69,
            0.79,
            0.89,
            0.9,
            0.95,
            0.99,
            1.0,
            1.09,
            1.19,
            1.29,
            1.39,
            1.49,
            1.59,
            1.69,
            1.79,
            1.89,
            1.9,
            1.95,
            1.99,
            2.0,
            2.09,
            2.19,
            2.29,
            2.39,
            2.49,
            2.59,
            2.69,
            2.79,
            2.89,
            2.9,
            2.95,
            2.99,
            3.0,
            3.09,
            3.19,
            3.29,
            3.39,
            3.49,
            3.59,
            3.69,
            3.79,
            3.89,
            3.9,
            3.95,
            3.99,
            4.0,
            4.09,
            4.19,
            4.29,
            4.39,
            4.49,
            4.59,
            4.69,
            4.79,
            4.89,
            4.9,
            4.95,
            4.99,
            5.0,
            5.09,
            5.19,
            5.29,
            5.39,
            5.49,
            5.59,
            5.69,
            5.79,
            5.89,
            5.9,
            5.95,
            5.99,
            6.0,
            6.09,
            6.19,
            6.29,
            6.39,
            6.49,
            6.59,
            6.69,
            6.79,
            6.89,
            6.9,
            6.95,
            6.99,
            7.0,
            7.09,
            7.19,
            7.29,
            7.39,
            7.49,
            7.59,
            7.69,
            7.79,
            7.89,
            7.9,
            7.95,
            7.99,
            8.0,
            8.09,
            8.19,
            8.29,
            8.39,
            8.49,
            8.59,
            8.69,
            8.79,
            8.89,
            8.9,
            8.95,
            8.99,
            9.0,
            9.09,
            9.19,
            9.29,
            9.39,
            9.49,
            9.59,
            9.69,
            9.79,
            9.89,
            9.9,
            9.95,
            9.99,
            10.0,
            10.49,
            10.9,
            10.95,
            10.99,
            11.0,
            11.49,
            11.9,
            11.95,
            11.99,
            12.0,
            12.49,
            12.9,
            12.95,
            12.99,
            13.0,
            13.49,
            13.9,
            13.95,
            13.99,
            14.0,
            14.49,
            14.9,
            14.95,
            14.99,
            15.0,
            15.49,
            15.9,
            15.95,
            15.99,
            16.0,
            16.49,
            16.9,
            16.95,
            16.99,
            17.0,
            17.49,
            17.9,
            17.95,
            17.99,
            18.0,
            18.49,
            18.9,
            18.95,
            18.99,
            19.0,
            19.49,
            19.9,
            19.95,
            19.99,
            20.0,
            20.49,
            20.9,
            20.95,
            20.99,
            21.0,
            21.49,
            21.9,
            21.95,
            21.99,
            22.0,
            22.49,
            22.9,
            22.95,
            22.99,
            23.0,
            23.49,
            23.9,
            23.95,
            23.99,
            24.0,
            24.49,
            24.9,
            24.95,
            24.99,
            25.0,
            25.49,
            25.9,
            25.95,
            25.99,
            26.0,
            26.49,
            26.9,
            26.95,
            26.99,
            27.0,
            27.49,
            27.9,
            27.95,
            27.99,
            28.0,
            28.49,
            28.9,
            28.95,
            28.99,
            29.0,
            29.49,
            29.9,
            29.95,
            29.99,
            30.0,
            30.49,
            30.9,
            30.95,
            30.99,
            31.0,
            31.49,
            31.9,
            31.95,
            31.99,
            32.0,
            32.49,
            32.9,
            32.95,
            32.99,
            33.0,
            33.49,
            33.9,
            33.95,
            33.99,
            34.0,
            34.49,
            34.9,
            34.95,
            34.99,
            35.0,
            35.49,
            35.9,
            35.95,
            35.99,
            36.0,
            36.49,
            36.9,
            36.95,
            36.99,
            37.0,
            37.49,
            37.9,
            37.95,
            37.99,
            38.0,
            38.49,
            38.9,
            38.95,
            38.99,
            39.0,
            39.49,
            39.9,
            39.95,
            39.99,
            40.0,
            40.49,
            40.9,
            40.95,
            40.99,
            41.0,
            41.49,
            41.9,
            41.95,
            41.99,
            42.0,
            42.49,
            42.9,
            42.95,
            42.99,
            43.0,
            43.49,
            43.9,
            43.95,
            43.99,
            44.0,
            44.49,
            44.9,
            44.95,
            44.99,
            45.0,
            45.49,
            45.9,
            45.95,
            45.99,
            46.0,
            46.49,
            46.9,
            46.95,
            46.99,
            47.0,
            47.49,
            47.9,
            47.95,
            47.99,
            48.0,
            48.49,
            48.9,
            48.95,
            48.99,
            49.0,
            49.49,
            49.9,
            49.95,
            49.99,
            50.0,
            50.9,
            50.99,
            51.0,
            51.9,
            51.99,
            52.0,
            52.9,
            52.99,
            53.0,
            53.9,
            53.99,
            54.0,
            54.9,
            54.99,
            55.0,
            55.9,
            55.99,
            56.0,
            56.9,
            56.99,
            57.0,
            57.9,
            57.99,
            58.0,
            58.9,
            58.99,
            59.0,
            59.9,
            59.99,
            60.0,
            60.9,
            60.99,
            61.0,
            61.9,
            61.99,
            62.0,
            62.9,
            62.99,
            63.0,
            63.9,
            63.99,
            64.0,
            64.9,
            64.99,
            65.0,
            65.9,
            65.99,
            66.0,
            66.9,
            66.99,
            67.0,
            67.9,
            67.99,
            68.0,
            68.9,
            68.99,
            69.0,
            69.9,
            69.99,
            70.0,
            70.9,
            70.99,
            71.0,
            71.9,
            71.99,
            72.0,
            72.9,
            72.99,
            73.0,
            73.9,
            73.99,
            74.0,
            74.9,
            74.99,
            75.0,
            75.9,
            75.99,
            76.0,
            76.9,
            76.99,
            77.0,
            77.9,
            77.99,
            78.0,
            78.9,
            78.99,
            79.0,
            79.9,
            79.99,
            80.0,
            80.9,
            80.99,
            81.0,
            81.9,
            81.99,
            82.0,
            82.9,
            82.99,
            83.0,
            83.9,
            83.99,
            84.0,
            84.9,
            84.99,
            85.0,
            85.9,
            85.99,
            86.0,
            86.9,
            86.99,
            87.0,
            87.9,
            87.99,
            88.0,
            88.9,
            88.99,
            89.0,
            89.9,
            89.99,
            90.0,
            90.9,
            90.99,
            91.0,
            91.9,
            91.99,
            92.0,
            92.9,
            92.99,
            93.0,
            93.9,
            93.99,
            94.0,
            94.9,
            94.99,
            95.0,
            95.9,
            95.99,
            96.0,
            96.9,
            96.99,
            97.0,
            97.9,
            97.99,
            98.0,
            98.9,
            98.99,
            99.0,
            99.9,
            99.99,
            100.0,
            100.99,
            101.99,
            102.99,
            103.99,
            104.99,
            105.99,
            106.99,
            107.99,
            108.99,
            109.0,
            109.99,
            110.99,
            111.99,
            112.99,
            113.99,
            114.99,
            115.99,
            116.99,
            117.99,
            118.99,
            119.0,
            119.99,
            120.99,
            121.99,
            122.99,
            123.99,
            124.99,
            125.99,
            126.99,
            127.99,
            128.99,
            129.0,
            129.99,
            130.99,
            131.99,
            132.99,
            133.99,
            134.99,
            135.99,
            136.99,
            137.99,
            138.99,
            139.0,
            139.99,
            140.99,
            141.99,
            142.99,
            143.99,
            144.99,
            145.99,
            146.99,
            147.99,
            148.99,
            149.0,
            149.99,
            150.0,
            150.99,
            151.99,
            152.99,
            153.99,
            154.99,
            155.99,
            156.99,
            157.99,
            158.99,
            159.0,
            159.99,
            160.99,
            161.99,
            162.99,
            163.99,
            164.99,
            165.99,
            166.99,
            167.99,
            168.99,
            169.0,
            169.99,
            170.99,
            171.99,
            172.99,
            173.99,
            174.99,
            175.99,
            176.99,
            177.99,
            178.99,
            179.0,
            179.99,
            180.99,
            181.99,
            182.99,
            183.99,
            184.99,
            185.99,
            186.99,
            187.99,
            188.99,
            189.0,
            189.99,
            190.0,
            190.99,
            191.99,
            192.99,
            193.99,
            194.99,
            195.99,
            196.99,
            197.99,
            198.99,
            199.0,
            199.99,
            200.0,
            204.99,
            209.0,
            209.99,
            214.99,
            219.0,
            219.99,
            224.99,
            229.0,
            229.99,
            234.99,
            239.0,
            239.99,
            244.99,
            249.0,
            249.99,
            250.0,
            254.99,
            259.0,
            259.99,
            264.99,
            269.0,
            269.99,
            274.99,
            279.0,
            279.99,
            284.99,
            289.0,
            289.99,
            290.0,
            294.99,
            299.0,
            299.99,
            300.0,
            304.99,
            309.0,
            309.99,
            314.99,
            319.0,
            319.99,
            324.99,
            329.0,
            329.99,
            334.99,
            339.0,
            339.99,
            344.99,
            349.0,
            349.99,
            350.0,
            354.99,
            359.0,
            359.99,
            364.99,
            369.0,
            369.99,
            374.99,
            379.0,
            379.99,
            384.99,
            389.0,
            389.99,
            390.0,
            394.99,
            399.0,
            399.99,
            400.0,
            449.99,
            499.99,
            599.99,
            699.99,
            799.99,
            899.99,
            999.99
        ]
    }
}