export enum Device {
    iPhone = "IPHONE",
    iPad = "IPAD",
    mac = "MAC",
    appleWatch = "APPLE_WATCH",
    appleTv = "APPLE_TV",
    appleVision = "APPLE_VISION"
}

export namespace Device {

    export const sortedAllCases: Device[] = [Device.iPhone, Device.iPad, Device.mac, Device.appleWatch, Device.appleTv, Device.appleVision]

    export function title(device: Device): string {
        switch (device) {
            case Device.iPhone: return "iPhone"
            case Device.iPad: return "iPad"
            case Device.mac: return "Mac"
            case Device.appleWatch: return "Apple Watch"
            case Device.appleTv: return "Apple TV"
            case Device.appleVision: return "Apple Vision"
        }
    }

    export function image(device: Device): string {
        switch (device) {
            case Device.iPhone: return require("../Assets/iphone@3x.png")
            case Device.iPad: return require("../Assets/ipad@3x.png")
            case Device.mac: return require("../Assets/macbook@3x.png")
            case Device.appleWatch: return require("../Assets/applewatch@3x.png")
            case Device.appleTv: return require("../Assets/appletv@3x.png")
            case Device.appleVision: return require("../Assets/visionpro@3x.png")
        }
    }
}