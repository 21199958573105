import React, {useEffect, useRef, useState} from "react";
import {Props as ViewProps, View} from "./SwiftUI/View";
import {Text} from "./SwiftUI/Text";
import {Button} from "./SwiftUI/Button";
import {Color} from "./SwiftUI/Enums/Color";
import {Alignment} from "./SwiftUI/Enums/Alignment";
import {HStack} from "./SwiftUI/HStack";
import {LinearGradient} from "./SwiftUI/LinearGradient";
import {UnitPoint} from "./SwiftUI/Enums/UnitPoint";

interface Props extends ViewProps {
    t?: string,
    lk?: string
}

export const ExpandableText: React.FC<Props> = (props) => {

    const textRef = useRef<HTMLDivElement>(null)
    const [expanded, setExpanded] = useState(false)
    const [truncated, setTruncated] = useState(false)

    useEffect(() => {
        const element = textRef.current
        if (!element) return
        const observer = new ResizeObserver((entries) => {
            let a = element.querySelector("span")?.clientHeight ?? 0
            let b = element.querySelector("span")?.scrollHeight ?? 0
            setTruncated(b > a)
        })
        observer.observe(element)
        return () => observer.disconnect()
    }, [textRef])

    function showMoreButton() {
        return (
            <HStack fit spacing={0}>
                <LinearGradient colors={[Color.clear, Color.white]} startPoint={UnitPoint.leading} endPoint={{x: 0.8, y: 0.5}}
                                frame={{width: 100}} />
                <View background={"white"}>
                    <Button t={"Show More"} action={() => { setExpanded(true) }}
                            padding={{horizontal: 6}}
                            background={Color.tableBackground}
                            cornerRadius={Infinity} />
                </View>
            </HStack>
        )
    }

    return (
        <div ref={textRef}>
            <Text {...props}
                  lineLimit={expanded ? undefined : props.lineLimit}
                  overlay={{alignment: Alignment.bottomTrailing, content: truncated && !expanded ? showMoreButton() : undefined}}
            />
        </div>
    )
}
