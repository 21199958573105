import {ConfigConstants} from "../Constants/ConfigConstants";

export class AppGenreStorage {

    static readonly shared = new AppGenreStorage()

    private static appGenres = [
        {
            "ITunesId": 6010,
            "title": "Navigation",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6004,
            "title": "Sports",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7005,
            "title": "Card",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7014,
            "title": "Role Playing",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6002,
            "title": "Utilities",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6020,
            "title": "Medical",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7018,
            "title": "Trivia",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6025,
            "title": "Stickers",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7002,
            "title": "Adventure",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7017,
            "title": "Strategy",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6013,
            "title": "Health & Fitness",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6001,
            "title": "Weather",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6018,
            "title": "Book",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7001,
            "title": "Action",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7003,
            "title": "Casual",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7010,
            "title": "Kids",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7015,
            "title": "Simulation",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6009,
            "title": "News",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6014,
            "title": "Games",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6026,
            "title": "Developer Tools",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6006,
            "title": "Reference",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6012,
            "title": "Lifestyle",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6027,
            "title": "Graphics & Design",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7006,
            "title": "Casino",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6008,
            "title": "Photo & Video",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6023,
            "title": "Food & Drink",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6017,
            "title": "Education",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6015,
            "title": "Finance",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6003,
            "title": "Travel",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6007,
            "title": "Productivity",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6022,
            "title": "Catalogs",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6021,
            "title": "Magazines & Newspapers",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7011,
            "title": "Music",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7016,
            "title": "Sports",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6005,
            "title": "Social Networking",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7019,
            "title": "Word",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7008,
            "title": "Educational",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7012,
            "title": "Puzzle",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6000,
            "title": "Business",
            "parentITunesId": 36
        },
        {
            "ITunesId": 6024,
            "title": "Shopping",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7007,
            "title": "Dice",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 7013,
            "title": "Racing",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6016,
            "title": "Entertainment",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7009,
            "title": "Family",
            "parentITunesId": 6014
        },
        {
            "ITunesId": 6011,
            "title": "Music",
            "parentITunesId": 36
        },
        {
            "ITunesId": 7004,
            "title": "Board",
            "parentITunesId": 6014
        }
    ].sort((a, b) => a.title.localeCompare(b.title))

    static getAllGenres(game: boolean) {
        return this.appGenres.filter(genre => (genre.parentITunesId === ConfigConstants.gameGenreId) === game)
    }
}