import React, {ReactElement} from "react";
import {Props as ViewProps, View} from "./View";

interface Props extends ViewProps {}

export const ZStack: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        width: "100%",
        height: "100%",
        ...props.style,
    }

    const containerStyle: React.CSSProperties = {
        width: "inherit",
        height: "inherit",
        display: "grid",
        alignItems: "center",
        justifyItems: "center"
    }

    const childStyle: React.CSSProperties = {
        gridColumn: 1,
        gridRow: 1,
    }

    return (
        <View {...props} style={style}>
            <View style={containerStyle}>
                {React.Children.map(props.children, child => {
                    if (!React.isValidElement(child)) { return child }
                    return React.cloneElement(child as ReactElement, {
                        ...child.props, style: {zIndex: 1, ...childStyle, ...child.props.style}
                    })
                })}
            </View>
        </View>
    );
};
