import React, {ReactElement} from "react"
import {Props as ViewProps, View} from "../../../Components/SwiftUI/View"
import {VStack} from "../../../Components/SwiftUI/VStack"
import {ForEach} from "../../../Components/SwiftUI/ForEach"
import {AppThumbnailV} from "../../../Components/AppThumbnailV"
import {useQuery} from "@apollo/client"
import {Color} from "../../../Components/SwiftUI/Enums/Color"
import {LoaderV} from "../../../Components/LoaderV";
import {NavigationLink} from "../../../Components/SwiftUI/NavigationLink";
import {useLocation, useParams} from "react-router-dom";
import {FilterAppsQuery} from "../../../Server API/GraphQL/App/Queries";
import {Spacer} from "../../../Components/SwiftUI/Spacer";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {Text} from "../../../Components/SwiftUI/Text";
import {HorizontalAlignment} from "../../../Components/SwiftUI/Enums/HorizontalAlignment";
import {Fonts} from "../../../Constants/Fonts";
import {AppSortBy} from "../../../Model/AppSortBy";
import {Axis} from "../../../Components/SwiftUI/Enums/Axis";
import {ScrollView} from "../../../Components/SwiftUI/ScrollView";
import {useStoredState} from "../../../Utilities/StoredState";
import {Picker} from "../../../Components/SwiftUI/Picker";
import {ConfigConstants} from "../../../Constants/ConfigConstants";
import {Toolbox} from "../../../Utilities/Toolbox";

interface Props extends ViewProps {}

export const AppsV: React.FC<Props> = (props) => {

    const { type } = useParams<{ type: string }>()
    const [appType, setAppType] = useStoredState<string | null>("type", null)
    let { state } = useLocation();

    let title = state?.title ?? "Apps"
    let appFilter = state?.appFilter ?? {includedGenreIds: [], excludedGenreIds: []}
    let appSort = state?.appSort ?? {}

    switch (type) {
        case "popular":
            title = "Popular"
            appSort = {by: AppSortBy.popularityDay}
            break
        case "preorders":
            title = "Pre-Orders"
            appFilter.preorder = true
            appSort = {by: AppSortBy.addedDate}
            break
    }

    function adjustedAppFilter() {
        let newAppFilter = Toolbox.copy(appFilter);

        if (newAppFilter.includedGenreIds.length === 0) {
            switch (appType) {
                case "apps":
                    if (!newAppFilter.excludedGenreIds.includes(ConfigConstants.gameGenreId)) {
                        newAppFilter.excludedGenreIds.push(ConfigConstants.gameGenreId);
                    }
                    break;
                case "games":
                    newAppFilter.includedGenreIds.push(ConfigConstants.gameGenreId);
                    break;
                default:
                    break;
            }
        }

        return newAppFilter;
    }

    const { loading, error, data, fetchMore } = useQuery(FilterAppsQuery, {
        variables: {
            appFilter: adjustedAppFilter(),
            appSort: appSort,
            page: 0
        }
    })

    function loadData() {
        fetchMore({ variables: {
            page: data.filterApps.nextPageable.pageNumber
        }})
    }

    const apps: any[] = data?.filterApps.content ?? []

    function filter(): ReactElement {
        return (
            <ScrollView axes={Axis.horizontal} showsIndicators={false}
                        padding={{vertical: 10}}
                        background={Color.white}
                        cornerRadius={{bottom: 14}}
                        shadow={{radius: 2, y: 4, color: "rgb(0, 0, 0, 0.08)"}}
            >
                <HStack padding={{horizontal: 16}}>
                    <Picker selection={[appType, setAppType]} frame={{height: 38}} padding={{horizontal: 4}} background={Color.tableBackground} cornerRadius={10}>
                        <Text t={"Apps & Games"} tag={null} />
                        <Text t={"Apps"} tag={"apps"} />
                        <Text t={"Games"} tag={"games"} />
                    </Picker>
                </HStack>
            </ScrollView>
        )
    }

    return (
        <VStack stretch navigationTitle={title} background={Color.groupedBackground}>

            {filter()}

            <Spacer frame={{height: 8}} />

            <VStack stretch spacing={6} alignment={HorizontalAlignment.leading}>

                <Text t={"Apps"}
                      font={Fonts.title}
                      foregroundStyle={Color.gray}
                      padding={{leading: 16}} />

                <VStack stretch spacing={0} background={Color.white} cornerRadius={14}>
                    <ForEach data={apps} id={app => app.id} content={app =>
                        <NavigationLink destination={`/app/${app.id}`} padding={{horizontal: 12, vertical: 6}}>
                            <AppThumbnailV app={app} frame={{height: 62}} />
                        </NavigationLink>
                    } />
                </VStack>

            </VStack>

            <LoaderV loading={loading} error={error} />
            {data?.filterApps.hasNext ? <View onAppear={loadData} /> : null}
        </VStack>
    )
}