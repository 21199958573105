import React from "react";
import {Props as ViewProps, View} from "./View";

interface Props extends ViewProps {
    minLength?: number;
}

export const Spacer: React.FC<Props> = (props) => {

    const style: React.CSSProperties = {
        flexGrow: 1,
        ...props.style
    };

    return (
        <View {...props} style={style} > </View>
    );
}