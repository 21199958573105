import React, {ReactElement} from "react";
import {Props as ViewProps} from "./View";

interface Props<T> extends ViewProps {
    data: T[];
    id: (item: T) => string | number
    content: (item: T) => ReactElement;
}

export const ForEach = <T,>(props: Props<T>): React.ReactElement => {
    return (
        <>
            {props.data.map(item => {
                return React.cloneElement(props.content(item), {
                    key: props.id(item), ...props
                })
            })}
        </>
    );
};