import {gql} from "@apollo/client";

export const GetDailyDealsQuery = gql`
query GetDailyDeals($page: Int!) {
    dailyDeals(page: $page) {
        content {
            ...DailyDealThumbnail
        }
        hasNext
        nextPageable {
            pageNumber
        }
    }
}
`