import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {Color} from "./SwiftUI/Enums/Color";
import {Text} from "./SwiftUI/Text";
import {HStack} from "./SwiftUI/HStack";
import {NavigationLink} from "./SwiftUI/NavigationLink";
import {Image} from "./SwiftUI/Image";
import {useStoredState} from "../Utilities/StoredState";
import {VStack} from "./SwiftUI/VStack";
import {TextAlignment} from "./SwiftUI/Enums/TextAlignment";
import {FontWeight} from "./SwiftUI/Enums/FontWeight";
import {Spacer} from "./SwiftUI/Spacer";

interface Props extends ViewProps {
    title: string,
    image: string,
    destination: string
    compact: boolean
}

export const TabItem: React.FC<Props> = (props) => {

    useStoredState("", "") // Causes re-render when window.location changes

    function parseURL(url: string) {
        const urlObj = new URL(url, window.location.origin)
        return {
            pathname: urlObj.pathname,
            searchParams: Object.fromEntries(urlObj.searchParams.entries())
        }
    }

    const currentURL = parseURL(window.location.href);
    const destinationURL = parseURL(props.destination);

    const isPathMatch = currentURL.pathname === destinationURL.pathname;

    const isQueryMatch = Object.keys(destinationURL.searchParams).every(key =>
        currentURL.searchParams[key] === destinationURL.searchParams[key]
    )

    const tint = (isPathMatch && isQueryMatch) ? Color.tint : Color.gray;

    function compactV() {
        return (
            <NavigationLink destination={props.destination}>
                <VStack spacing={1} >

                    <Spacer />

                    <Image systemName={props.image}
                           frame={{width: 34, height: 26}}
                           foregroundStyle={tint} />

                    <Spacer />

                    <Text t={props.title}
                          foregroundStyle={tint}
                          font={{size: 10, weight: FontWeight.medium}}
                          multilineTextAlignment={TextAlignment.center} />

                </VStack>
            </NavigationLink>
        )
    }

    function regularV() {
        return (
            <NavigationLink stretch destination={props.destination}>
                <HStack spacing={16} padding={{horizontal: 16, vertical: 14}}>

                    <Image systemName={props.image}
                           frame={{width: 32, height: 28}}
                           foregroundStyle={tint} />

                    <Text t={props.title}
                          foregroundStyle={tint}
                          font={{weight: FontWeight.medium}} />

                </HStack>
            </NavigationLink>
        )
    }

    return props.compact ? compactV() : regularV()
}
