import {PriceTerritory} from "../Constants/DefaultTerritory";

export class LocaleStorage {

    static readonly shared = new LocaleStorage()

    formatPrice(priceTier: number | null): string {
        if (priceTier == null) {
            return "---";
        }
        if (priceTier === 0) {
            return "Free";
        }
        if (PriceTerritory.default.pricePoints.length > priceTier) {
            return `$${this.rawPriceUS(priceTier)}`;
        } else {
            return "?";
        }
    }

    rawPriceUS(priceTier: number): number {
        return PriceTerritory.default.pricePoints[priceTier];
    }
}